import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./candidateprescreen.scss";
import Modal from "react-bootstrap/Modal";
//import SubmitButton from "../../../../ui/submitButton";
import { useDispatch, useSelector } from "react-redux";
import {
  showSuccess,
  showError,
  clearMessage,
} from "../../../../../slice/utils/message/messageSlice";
import {
  addNew,
  updateValue,
  listAll,
  detailsById,
  deleteValue,
  clearState,
} from "../../../../../slice/account/precreeningForm/precreeningSlice";

//import TableLoader from "../../../../ui/tableLoader";
import DeleteModal from "../../../../ui/delete";
//import PrescreenMoreInfoPophover from './MoreInfoPopover';
import { Tab, Nav } from "react-bootstrap";
import RequirementTab from "./requirements/Requirement";
import InstructionOverview from "./inustructions/Overview";
// import CallscriptOverview from './callscript/Overview'
import AllscriptOverview from "./allscript/Overview";
import QuestionOverview from "./questions/Overview";
import FaqOverview from "./faq/Overview";
import ScreeningForm from "./presrceenform/ScreeningForm";
import ArchivedForm from "./presrceenform/ArchivedForm";
import CandidateScreenForm from "./candidateform/ScreenForm";
//import InterviewNotes from './interviewnotes';

const CandidatePrescreen = () => {
  const [show, setShow] = useState(false);
  const [isClearInput, setClearInput] = useState(false);

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.auth);
  const {
    dataList,
    details,
    isDetails,
    isSuccess,
    isDelete,
    isError,
    isLoading,
    message,
  } = useSelector((state) => state.precreening);
  const { isInserted } = useSelector((state) => state.candidatePrecreening);

  // useEffect(() => {
  //     if (isInserted) {
  //         setSuccess(false);
  //         setLoading(false);
  //         setShow(false)
  //         dispatch(showSuccess({ msg: 'Data submitted successfully' }))
  //     }
  // }, [isInserted])

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const [isShowTable, setShowTable] = useState(false);
  const [buttonTxt, setButtonTxt] = useState("Save");
  const [showButton, setShowButton] = useState(true);
  const [showDelete, setShowDelete] = useState(false);

  const companyId = useState(1);

  const [rowId, setRowId] = useState(0);
  const [candidateName, setCandidateName] = useState("");
  const [company, setCompany] = useState("");
  const [jobApplied, setJobApplied] = useState("");
  const [jobDescription, setJobDescription] = useState("");
  const [jobRequirment, setJobRequirement] = useState("");
  const [salary, setSalary] = useState("");
  const [workExperience, setWorkExperience] = useState("");
  const [motivation, setMotivation] = useState("");
  const [reasionLeave, setReasonLeave] = useState("");
  const [reLocation, setRelocation] = useState("");
  const [vaccineInfo, setVaccineInfo] = useState("");
  const [availibilty, setAvailability] = useState("");
  const [othersInfo, setOthersInfo] = useState("");

  useEffect(() => {
    setShowTable(false);
    setShowButton(true);
    setButtonTxt("Save");
    clearFormData();
  }, []);

  const clearFormData = () => {
    setRowId(0);
    setCandidateName("");
    setCompany("");
    setJobApplied("");
    setJobDescription("");
    setJobRequirement("");
    setSalary("");
    setWorkExperience("");
    setMotivation("");
    setReasonLeave("");
    setRelocation("");
    setVaccineInfo("");
    setAvailability("");
    setOthersInfo("");
  };
  const showForm = () => {
    clearFormData();
    setShowButton(true);
    setShowTable(false);
  };

  const showTable = () => {
    setShowTable(true);
    dispatch(listAll({}));
  };

  const reloadData = () => {
    setClearInput(true);
    clearFormData();
    setShowButton(true);
    setShowTable(false);
    setButtonTxt("Save");
  };

  const getDetail = async (id, type) => {
    if (type == "view") {
      setShowButton(false);
      setShowTable(false);
    } else {
      setShowButton(true);
      setButtonTxt("Update");
      setShowTable(false);
    }
    await dispatch(detailsById({ id }));
  };

  const deleteData = (id) => {
    setRowId(id);

    setShowDelete(true);
  };

  const handleCloseDelete = () => {
    setRowId(0);
    setShowDelete(false);
  };

  const deleteItem = async () => {
    await dispatch(deleteValue({ id: rowId }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        dispatch(clearMessage());
        if (rowId == "0") {
          await dispatch(
            addNew({
              companyId: 1,
              candidateName,
              company,
              jobApplied,
              jobDescription,
              jobRequirment,
              salary,
              workExperience,
              motivation,
              reasionLeave,
              reLocation,
              vaccineInfo,
              availibilty,
            })
          );
        } else {
          await dispatch(
            updateValue({
              id: rowId,
              companyId: 1,
              candidateName,
              company,
              jobApplied,
              jobDescription,
              jobRequirment,
              salary,
              workExperience,
              motivation,
              reasionLeave,
              reLocation,
              vaccineInfo,
              availibilty,
            })
          );
        }
        if (isLoading) {
          setSuccess(false);
          setLoading(true);
        }
      } catch (err) {
        setSuccess(false);
        setLoading(false);
        dispatch(showError({ msg: "Something went wrong! Try again." }));
      }
    }
  };

  const validateForm = () => {
    let formIsValid = true;
    if (!candidateName) {
      formIsValid = false;
      dispatch(showError({ msg: "Please fill candidate name" }));
    }
    if (!company) {
      formIsValid = false;
      dispatch(showError({ msg: "Please fill company name" }));
    }
    return formIsValid;
  };

  useEffect(() => {
    if (isError) {
      setSuccess(false);
      setLoading(false);
      dispatch(clearState());
      dispatch(showError({ msg: message }));
    }
    if (isSuccess) {
      dispatch(clearState());
      clearFormData();
      setSuccess(true);
      setLoading(false);
      dispatch(showSuccess({ msg: message }));
      setShowTable(true);
    }
    if (isDetails) {
      setRowId(details.id);
      setCandidateName(details.candidateName);
      setCompany(details.company);
      setJobApplied(details.jobApplied);
      setJobDescription(details.jobDescription);
      setJobRequirement(details.jobRequirment);
      setSalary(details.salary);
      setWorkExperience(details.workExperience);
      setMotivation(details.motivation);
      setReasonLeave(details.reasionLeave);
      setRelocation(details.reLocation);
      setVaccineInfo(details.vaccineInfo);
      setAvailability(details.availibilty);
      setOthersInfo(details.availibilty);
      dispatch(clearState());
    }
    if (isDelete) {
      setRowId(0);
      setShowDelete(false);
      dispatch(clearState());
      dispatch(showSuccess({ msg: message }));
    }
  }, [isDetails, isError, isSuccess, dispatch, message]);

  return (
    <>
      {showDelete ? (
        <DeleteModal
          loading={loading}
          success={success}
          handleClose={handleCloseDelete}
          deleteItem={deleteItem}
        />
      ) : null}
      <Link to="#" onClick={handleShow}>
        <i className="far fa-user"></i>
      </Link>
      <Modal show={show} onHide={handleClose} className="dk-candprescreenModal">
        <Modal.Header closeButton className="dk-candprescreenModalHeader">
          {/* <div className="dk-prescrenrefreshIcon" title="Reload" onClick={reloadData}>
                        <i className="fas fa-sync-alt"></i>
                    </div> */}
          <Modal.Title>Candidate Screening Form</Modal.Title>
        </Modal.Header>
        <Modal.Body className="dk-candprescreenModalBody">
          <Tab.Container defaultActiveKey="prescreen-faq">
            <div className="dkg-canpresrcreen-tab-mainCon">
              <div className="dkg-canpresrcreen-tabCon">
                <Nav
                  variant="pills"
                  className="flex-row dkg-canpresrcreen-tab-navpills"
                >
                  {/* <Nav.Item className="dkg-canpresrcreen-tab-nav-item">
                    <Nav.Link
                      eventKey="prescreen-form"
                      className="dkg-canpresrcreen-tab-navlink"
                    >
                      NEW FORM
                    </Nav.Link>
                  </Nav.Item> */}
                  {/* <Nav.Item className='dkg-canpresrcreen-tab-nav-item'>
                                        <Nav.Link eventKey="interview-notes" className='dkg-canpresrcreen-tab-navlink'>RECRUITER NOTES</Nav.Link>
                                    </Nav.Item> */}
                  {/* <Nav.Item className='dkg-canpresrcreen-tab-nav-item'>
                                        <Nav.Link eventKey="prescreen-requrements" className='dkg-canpresrcreen-tab-navlink'>JOB REQUIREMENTS</Nav.Link>
                                    </Nav.Item> */}
                  {/* <Nav.Item className='dkg-canpresrcreen-tab-nav-item'>
                                        <Nav.Link eventKey="prescreen-save" className='dkg-canpresrcreen-tab-navlink' style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>Saved Form
                                            {
                                                (isShowTable) ? <div className="dk-prescreensaveIcon ml-2" onClick={showForm}>
                                                    <i className="far fa-file-alt"></i>
                                                </div> : <div className="dk-prescreensaveIcon ml-2" onClick={showTable}>
                                                    <i className="fas fa-save"></i>
                                                </div>
                                            }
                                        </Nav.Link>
                                    </Nav.Item> */}
                  {/* <Nav.Item className="dkg-canpresrcreen-tab-nav-item">
                    <Nav.Link
                      eventKey="prescreen-save"
                      className="dkg-canpresrcreen-tab-navlink"
                    >
                      SAVED
                    </Nav.Link>
                  </Nav.Item> */}
                  {/* <Nav.Item className='dkg-canpresrcreen-tab-nav-item'>
                                        <Nav.Link eventKey="prescreen-archived" className='dkg-canpresrcreen-tab-navlink'>ARCHIVED</Nav.Link>
                                    </Nav.Item> */}
                  {/* <Nav.Item className="dkg-canpresrcreen-tab-nav-item">
                    <Nav.Link
                      eventKey="prescreen-instructions"
                      className="dkg-canpresrcreen-tab-navlink"
                    >
                      GUIDELINES
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-canpresrcreen-tab-nav-item">
                    <Nav.Link
                      eventKey="prescreen-callscript"
                      className="dkg-canpresrcreen-tab-navlink"
                    >
                      TEMPLATES
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-canpresrcreen-tab-nav-item">
                    <Nav.Link
                      eventKey="prescreen-questions"
                      className="dkg-canpresrcreen-tab-navlink"
                    >
                      CANDIDATE QUERY
                    </Nav.Link>
                  </Nav.Item> */}
                  <Nav.Item className="dkg-canpresrcreen-tab-nav-item">
                    <Nav.Link
                      eventKey="prescreen-faq"
                      className="dkg-canpresrcreen-tab-navlink"
                    >
                      CLIENTS FAQ
                    </Nav.Link>
                  </Nav.Item>
                  {/* <Nav.Item className='dkg-canpresrcreen-tab-nav-item'>
                                        <Nav.Link eventKey="prescreen-rc" className='dkg-canpresrcreen-tab-navlink'>RC</Nav.Link>
                                    </Nav.Item> */}
                </Nav>
                <Tab.Content className="dkg-canpresrcreen-tabcontent">
                  <Tab.Pane
                    eventKey="prescreen-form"
                    className="dkg-canpresrcreen-tabpane"
                    style={{ padding: "15px 0", backgroundColor: "#fff" }}
                  >
                    {/* {
                          (!isShowTable) ?
                              <div className="dk-candprescreeningTableCon">
                                  <table className="table dk-candprescreeningTable">
                                      <tbody>
                                          <tr>
                                              <td>
                                                  <div className='dk-prescreenleftCon'>
                                                      <span className='dk-prscreenTitle'>Candidate Details</span>
                                                      <span className='dkprescreen-info ml-2'>
                                                          <PrescreenMoreInfoPophover />
                                                      </span>
                                                  </div>
                                              </td>
                                              <td>
                                                  <input type="text" readOnly={!showButton} className="form-control candidateName" placeholder="" value={candidateName} onChange={(e) => setCandidateName(e.target.value)} />
                                              </td>
                                          </tr>
                                          <tr>
                                              <td>
                                                  <div className='dk-prescreenleftCon'>
                                                      <span className='dk-prscreenTitle'>Job Description</span>
                                                      <span className='dkprescreen-info ml-2'>
                                                          <PrescreenMoreInfoPophover />
                                                      </span>
                                                  </div>
                                              </td>
                                              <td>
                                                  <textarea row="2" readOnly={!showButton} className="form-control jobDescription" placeholder="" value={jobDescription} onChange={(e) => setJobDescription(e.target.value)} />
                                              </td>
                                          </tr>
                                          <tr>
                                              <td>
                                                  <div className='dk-prescreenleftCon'>
                                                      <span className='dk-prscreenTitle'>Job Requirements</span>
                                                      <span className='dkprescreen-info ml-2'>
                                                          <PrescreenMoreInfoPophover />
                                                      </span>
                                                  </div>
                                              </td>
                                              <td>
                                                  <textarea row="2" readOnly={!showButton} className="form-control jobRequirement" placeholder="" value={jobRequirment} onChange={(e) => setJobRequirement(e.target.value)} />
                                              </td>
                                          </tr>
                                          <tr>
                                              <td>
                                                  <div className='dk-prescreenleftCon'>
                                                      <span className='dk-prscreenTitle'>Working Location</span>
                                                      <span className='dkprescreen-info ml-2'>
                                                          <PrescreenMoreInfoPophover />
                                                      </span>
                                                  </div>
                                              </td>
                                              <td>
                                                  <textarea row="2" readOnly={!showButton} className="form-control jobRequirement" placeholder="" defaultValue={""} />
                                              </td>
                                          </tr>
                                          <tr>
                                              <td>
                                                  <div className='dk-prescreenleftCon'>
                                                      <span className='dk-prscreenTitle'>Salary Negotiation</span>
                                                      <span className='dkprescreen-info ml-2'>
                                                          <PrescreenMoreInfoPophover />
                                                      </span>
                                                  </div>
                                              </td>
                                              <td>
                                                  <textarea row="2" readOnly={!showButton} className="form-control jobRequirement" placeholder="" defaultValue={""} />
                                              </td>
                                          </tr>
                                          <tr>
                                              <td>
                                                  <div className='dk-prescreenleftCon'>
                                                      <span className='dk-prscreenTitle'>Joining Discussion</span>
                                                      <span className='dkprescreen-info ml-2'>
                                                          <PrescreenMoreInfoPophover />
                                                      </span>
                                                  </div>
                                              </td>
                                              <td>
                                                  <textarea row="2" readOnly={!showButton} className="form-control jobRequirement" placeholder="" defaultValue={""} />
                                              </td>
                                          </tr>
                                          <tr>
                                              <td>
                                                  <div className='dk-prescreenleftCon'>
                                                      <span className='dk-prscreenTitle'>Resume Overview</span>
                                                      <span className='dkprescreen-info ml-2'>
                                                          <PrescreenMoreInfoPophover />
                                                      </span>
                                                  </div>
                                              </td>
                                              <td>
                                                  <textarea row="2" readOnly={!showButton} className="form-control jobRequirement" placeholder="" defaultValue={""} />
                                              </td>
                                          </tr>
                                          <tr>
                                              <td>
                                                  <div className='dk-prescreenleftCon'>
                                                      <span className='dk-prscreenTitle'>Reasons For Applying</span>
                                                      <span className='dkprescreen-info ml-2'>
                                                          <PrescreenMoreInfoPophover />
                                                      </span>
                                                  </div>
                                              </td>
                                              <td>
                                                  <textarea row="2" readOnly={!showButton} className="form-control jobRequirement" placeholder="" defaultValue={""} />
                                              </td>
                                          </tr>
                                          <tr>
                                              <td>
                                                  <div className='dk-prescreenleftCon'>
                                                      <span className='dk-prscreenTitle'>Why Suitable For Job</span>
                                                      <span className='dkprescreen-info ml-2'>
                                                          <PrescreenMoreInfoPophover />
                                                      </span>
                                                  </div>
                                              </td>
                                              <td>
                                                  <textarea row="2" readOnly={!showButton} className="form-control jobRequirement" placeholder="" defaultValue={""} />
                                              </td>
                                          </tr>
                                          <tr>
                                              <td>
                                                  <div className='dk-prescreenleftCon'>
                                                      <span className='dk-prscreenTitle'>Relocation If Needed</span>
                                                      <span className='dkprescreen-info ml-2'>
                                                          <PrescreenMoreInfoPophover />
                                                      </span>
                                                  </div>
                                              </td>
                                              <td>
                                                  <textarea row="2" readOnly={!showButton} className="form-control jobRequirement" placeholder="" defaultValue={""} />
                                              </td>
                                          </tr>
                                          <tr>
                                              <td>
                                                  <div className='dk-prescreenleftCon'>
                                                      <span className='dk-prscreenTitle'>Documents For Offer</span>
                                                      <span className='dkprescreen-info ml-2'>
                                                          <PrescreenMoreInfoPophover />
                                                      </span>
                                                  </div>
                                              </td>
                                              <td>
                                                  <textarea row="2" readOnly={!showButton} className="form-control vaccineInfo" placeholder="" value={vaccineInfo} onChange={(e) => setVaccineInfo(e.target.value)} />
                                              </td>
                                          </tr>
                                          <tr>
                                              <td>
                                                  <div className='dk-prescreenleftCon'>
                                                      <span className='dk-prscreenTitle'>Interviews Availability</span>
                                                      <span className='dkprescreen-info ml-2'>
                                                          <PrescreenMoreInfoPophover />
                                                      </span>
                                                  </div>
                                              </td>
                                              <td>
                                                  <textarea row="2" readOnly={!showButton} className="form-control availability" placeholder="" value={availibilty} onChange={(e) => setAvailability(e.target.value)} />
                                              </td>
                                          </tr>
                                          <tr>
                                              <td>
                                                  <div className='dk-prescreenleftCon'>
                                                      <span className='dk-prscreenTitle'>Any Others Informations</span>
                                                      <span className='dkprescreen-info ml-2'>
                                                          <PrescreenMoreInfoPophover />
                                                      </span>
                                                  </div>
                                              </td>
                                              <td>
                                                  <textarea row="2" readOnly={!showButton} className="form-control availability" placeholder="" value={othersInfo} onChange={(e) => setOthersInfo(e.target.value)} />
                                              </td>
                                          </tr>
                                      </tbody>
                                  </table>
                                  {
                                      (showButton) ?
                                          <div className="dk-prescreenbtnCon">
                                              <SubmitButton txt={buttonTxt} loading={loading} success={success} onClick={handleSubmit} position="justify-content-center" className="dk-prescreenbtn" />
                                          </div> : null
                                  }
                              </div> :
                              <div className='dk-newprescrenformTableCon'>
                                  <table className='dk-newprescrenformTable table table-bordered'>
                                      <thead>
                                          <tr>
                                              <th>Candidate</th>
                                              <th>Company name</th>
                                              <th>Action</th>
                                          </tr>
                                      </thead>
                                      <tbody>
                                          {
                                              (isLoading) ? <TableLoader colspan={3} /> :
                                                  dataList.map((item, index) =>
                                                      <tr>
                                                          <td>{item.candidateName}</td>
                                                          <td>{item.company}</td>
                                                          <td>
                                                              <div className="newactionCols">
                                                                  <Link to="#" className="dk-prescreen-viewbtn mr-2" onClick={(e) => getDetail(item.id, 'view')}>
                                                                      <i className="fa fa-eye" aria-hidden="true"></i>
                                                                  </Link>
                                                                  <Link to="#" className="dk-prescreen-editbtn mr-2" onClick={(e) => getDetail(item.id, 'edit')}>
                                                                      <i className="far fa-edit"></i>
                                                                  </Link>
                                                                  <Link to="#" className="dk-prescreen-deletebtn" onClick={(e) => deleteData(item.id)}>
                                                                      <i className="fa fa-trash" aria-hidden="true"></i>
                                                                  </Link>
                                                              </div>
                                                          </td>
                                                      </tr>
                                                  )
                                          }
                                      </tbody>
                                  </table>
                              </div>
                      } */}
                    <CandidateScreenForm
                      handleClose={handleClose}
                      dataRowId=""
                      isClearInput={isClearInput}
                    />
                  </Tab.Pane>
                  {/* <Tab.Pane eventKey="prescreening-form" className='dkg-canpresrcreen-tabpane'>
                                        <ScreeningForm />
                                    </Tab.Pane> */}
                  {/* <Tab.Pane eventKey="interview-notes" className='dkg-canpresrcreen-tabpane p-3'>
                                        <InterviewNotes />
                                    </Tab.Pane> */}
                  <Tab.Pane
                    eventKey="prescreen-requrements"
                    className="dkg-canpresrcreen-tabpane"
                  >
                    <RequirementTab />
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="prescreen-instructions"
                    className="dkg-canpresrcreen-tabpane"
                  >
                    <InstructionOverview />
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="prescreen-callscript"
                    className="dkg-canpresrcreen-tabpane"
                  >
                    <AllscriptOverview />
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="prescreen-questions"
                    className="dkg-canpresrcreen-tabpane"
                  >
                    <QuestionOverview />
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="prescreen-faq"
                    className="dkg-canpresrcreen-tabpane"
                  >
                    <FaqOverview />
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="prescreen-save"
                    className="dkg-canpresrcreen-tabpane"
                    style={{ padding: "15px 0", backgroundColor: "#fff" }}
                  >
                    <ScreeningForm />
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="prescreen-archived"
                    className="dkg-canpresrcreen-tabpane"
                    style={{ padding: "15px 0", backgroundColor: "#fff" }}
                  >
                    <ArchivedForm />
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </div>
          </Tab.Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CandidatePrescreen;
