import React, { useEffect, useState, useMemo, lazy, Suspense } from 'react';
import './screening-form.scss'
import DeleteModal from "./modal/delete/Delete";
import { useTable, useSortBy, useFilters, useGlobalFilter, usePagination, useAsyncDebounce } from 'react-table';
import { useDispatch, useSelector } from "react-redux";
import TableLoader from '../../../../../ui/tableLoader';
import $ from "jquery";
import { showSuccess } from '../../../../../../slice/utils/message/messageSlice';
import Checkbox from "./modal/checkbox/radioBtn";
import { clearState, clearTextBox, fetchScreeningList, fetchById, deleteFunction } from "../../../../../../slice/candidatePrecreeningForm/candidatePrecreeningSlice";
import CustomDateFormat from "../../../../../ui/dateFormats/CustomDateFormat";
import EditPrescreenModal from '../candidateform/ScreenForm';
const TableSuspence = lazy(() => import('../../../../../ui/tableLoader/tableSuspence'));

const useInstance = (instance) => {
    if (instance && instance.getInstanceCallback) {
        instance.getInstanceCallback(instance);
    }
};

function Table({ columns, data, loading, getInstanceCallback }) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        footerGroups,
        rows,
        page,
        prepareRow,
        state,
        setFilter,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        preGlobalFilteredRows,
        setGlobalFilter,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            getInstanceCallback,
            autoResetPage: false,
            autoResetSortBy: false,
            autoResetFilters: false,
            autoResetRowState: false,
            initialState: {
                pageIndex: 0, pageSize: 100
            },
        },
        useFilters,
        useGlobalFilter,
        useSortBy, usePagination, (hooks) => hooks.useInstance.push(useInstance)
    )

    const handlePageChange = (event, value) => {
        gotoPage(Number(value) - 1);
    };

    const [filterType, setFilterType] = useState('')

    const doFilter = React.useCallback(
        useAsyncDebounce((id) => {
            setFilter(filterType, id)
        }, 200),
        [filterType]
    );

    return (
        <React.Fragment>
            <table className="dk-newprescrenformTable table table-bordered" {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                // Add the sorting props to control sorting. For this example
                                // we can add them into the header props
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {column.render('Header')}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map(
                        (row, i) => {
                            prepareRow(row);
                            return (
                                (loading) ?
                                    [...Array(10)].map((e, i) =>
                                        <tr>
                                            <TableLoader colspan="22" />
                                        </tr>
                                    ) :
                                    <Suspense fallback={<TableSuspence colspan="22" />}>
                                        <tr {...row.getRowProps()}>
                                            {row.cells.map((cell) => (
                                                <td
                                                    {...cell.getCellProps([
                                                        {
                                                            className: cell.column.className, // pay attention to this
                                                            style: cell.column.style,
                                                            // set here your other custom props
                                                        },
                                                    ])}
                                                >
                                                    {cell.render('Cell')}
                                                </td>
                                            ))}
                                        </tr>
                                    </Suspense>
                            )
                        }
                    )}
                </tbody>
                <tfoot className='d-none'>
                    {footerGroups.map(group => (
                        <tr {...group.getFooterGroupProps()}>
                            {group.headers.map(column => (
                                <td {...column.getFooterProps()}>{column.render('Footer')}</td>
                            ))}
                        </tr>
                    ))}
                </tfoot>
            </table>
        </React.Fragment>
    )
}

function debounce(func, wait, immediate) {
    var timeout;
    return (...args) => {
        var context = this;
        var later = () => {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
    isClearAll,
    restClearAllFilter }) {
    const count = preGlobalFilteredRows.length
    const [value, setValue] = useState("")

    const onChangeSearchTitle = (e) => {
        const { value: nextValue } = e.target;
        setValue(e.target.value)
        debouncedSave(nextValue);
    };

    const debouncedSave = React.useCallback(
        debounce(nextValue => {
            setGlobalFilter(nextValue)
        }, 1000),
    );

    const clearSearch = () => {
        setValue('')
        setTimeout(() => {
            setGlobalFilter(undefined)
        }, 200)
    }

    useEffect(() => {
        if (isClearAll) {
            clearSearch()
            restClearAllFilter();
        }
    }, [isClearAll])

    return (
        <React.Fragment>

        </React.Fragment>
    )
}

const ArchivedForm = () => {

    const dispatch = useDispatch();
    const { isLoading, screanningList, isUpdate, isDelete } = useSelector(state => state.candidatePrecreening);

    const [list, setList] = useState([]);
    const [data, setData] = useState([])


    const [rowId, setRowId] = useState(0)
    const [isViewModal, setIsViewModal] = useState(false)
    const [rowData, setRowData] = useState({})

    useEffect(() => {
        const fetchList = async () => {
            try {
                await dispatch(fetchScreeningList({}))
            } catch (err) {
                console.log(err)
            }
        }
        fetchList();

        if (isUpdate) {
            SetshowEdit(false)
            setShowList(true)
            dispatch(clearTextBox())
            dispatch(clearState())
            setSuccess(false);
            setLoading(false);
            dispatch(showSuccess({ msg: 'Details updated successfully' }))
        }
        if (isDelete) {
            SetshowEdit(false)
            setIsActive(false)
            setShowList(true)
            dispatch(clearTextBox())
            dispatch(clearState())
            setSuccess(false);
            setLoading(false);
            dispatch(showSuccess({ msg: 'Record deleted successfully' }))
        }
    }, [isUpdate, isDelete])

    useEffect(() => {
        const fetchList = async () => {
            try {
                await dispatch(fetchScreeningList({}))
            } catch (err) {
                console.log(err)
            }
        }
        fetchList();
    }, [dispatch])

    useEffect(() => {
        if (!isLoading) {
            setTimeout(() => {
                setData(screanningList)
            }, 200)
        }

    }, [isLoading, dispatch])

    const [checkBtnTxt, setCheckBtnTxt] = useState('Check All')
    const [countCheck, setCountCheck] = useState(0)
    const [dataRowId, setDataRowId] = useState(0)
    const [isActive, setIsActive] = useState(false);
    const [showEdit, SetshowEdit] = useState(false);

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const checkActiveExtraDiv = (isShowExitra, checkedBoxCount) => {

        if (isShowExitra) {
            setIsActive(true)
            setCountCheck(checkedBoxCount)
        } else {
            setIsActive(false)
            setCountCheck(checkedBoxCount)
        }
    }

    const checkExtraDivBtnTxt = (btnTxt) => {
        setCheckBtnTxt(btnTxt)
    }

    // const toggleCheckAll = () => {
    //     var checkedBoxCount = $('input[name="chkScreanCandidate[]"]:checked').length;
    //     var totalCheckboxes = $('input[name="chkScreanCandidate[]"]').length;
    //     if (checkedBoxCount < totalCheckboxes) {
    //         setCheckBtnTxt('Uncheck All')
    //         setCountCheck(totalCheckboxes)
    //         $('.chkCandidate').prop('checked', true);
    //     }
    //     if (checkedBoxCount == totalCheckboxes) {
    //         setCheckBtnTxt('Check All')
    //         setCountCheck(0)
    //         $('.chkCandidate').prop('checked', false);
    //         setIsActive(false)
    //     }
    // }

    const handleToggle = () => {
        $('.chkCandidate').prop('checked', false);
        setIsActive(false);
    };

    
    const deleteItemBulk = async (idd) => {
        await dispatch(deleteFunction({ id: idd }))        
    }

    const [dataMoveTo, SetDataMoveTo] = useState('')

    const moveRecords = async (e) => {
        SetDataMoveTo(e.target.dataset.moveto)
    }

    useEffect(() => {
        if (dataMoveTo !== '') {
            (async () => {
                var checkedBoxCount = $('input[name="chkCandidate[]"]:checked').length;
                if (checkedBoxCount == 0) {
                } else {
                    var canIds = [];
                    $.each($(".chkCandidate:checked"), function () {
                        canIds.push($(this).val());
                    });
                    console.log(dataMoveTo)
                    //await dispatch(moveRecordsToPaid({ id: dataRowId, status: dataMoveTo }))
                    $('.chkCandidate').prop('checked', false);
                    setIsActive(false);
                }
            })();
        }
    }, [dataMoveTo])


    const columns = useMemo(() => [
        {
            Header: 'SNo.',
            accessor: 'id',
            Cell: ({ cell }) => {
                return (
                    <span className="dk-checkinputIconCon">
                        {Number(cell.row.id) + 1}
                        <div className='dk-checkinputIcon'>
                            <Checkbox id={cell.row.original.id} checkActiveExtraDiv={checkActiveExtraDiv} checkExtraDivBtnTxt={checkExtraDivBtnTxt} setDataRowId={setDataRowId} />
                        </div>
                    </span>
                )
            }
        },
        {
            Header: 'Data & Time',
            //accessor: 'appDate'
            accessor: 'createdAt',
            Cell: ({ cell }) => {
                return <CustomDateFormat date={cell.row.original.createdAt} format='DD MMM, HH:mm' />
            }
        },
        {
            Header: 'RC',
            accessor: 'recruiterDetail',
            Cell: ({ cell }) => {
                return (
                    <div className='dk-prescreen-recuiter-con'>
                        <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + cell.row.original.recruiterDetail.profileImage} alt='Recruiter' className='dk-prescreen-recuiter-img' />
                    </div>
                )
            }
        },
        {
            Header: 'AM',
            accessor: 'am',
            Cell: ({ cell }) => {
                return (
                    <div className='dk-prescreen-recuiter-con'>
                        <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + cell.row.original.amDetail.profileImage} alt='Recruiter' className='dk-prescreen-recuiter-img' />
                    </div>
                )
            }
        },
        {
            Header: 'Name of Candidate',
            accessor: 'name',

        },
        {
            Header: 'Current Location',
            accessor: 'currentLocation'
        },
        {
            Header: 'Company',
            accessor: 'company'
        },
        {
            Header: 'Language',
            accessor: 'language'
        },
        {
            Header: 'Project',
            accessor: 'project'
        },
        {
            Header: 'Role Type',
            accessor: 'roleType'
        },
        {
            Header: 'CV Source',
            accessor: 'cvSource',

        },
        // {
        //     Header: 'Actions',
        //     accessor: 'actions',
        //     Cell: ({ cell }) => {
        //         return (
        //             <>
        //                 <div className='dkg-action-cols'>
        //                     <Link to="#" className='dkg-edit-btn mr-2'><FaEdit onClick={() => editFunction(cell.row.original.id)} /></Link>
        //                     <DeleteModal loading={loading} success={success} deleteItem={() => deleteItemBulk(cell.row.original.id)} tableName="candidate_prescreening_forms" />

        //                 </div>
        //             </>
        //         )
        //     }
        // },

    ], [])

    const [instanceData, setInstanceData] = useState('0')
    const reactTableInstance = (instance) => {
        if (instance) {
            setList(instance.flatRows)
            setInstanceData(instance)
        }
    };

    const editFunction = async (id) => {
        await dispatch(fetchById({ id: id }))
        SetshowEdit(true)
        setShowList(false)
        setIsActive(false)
    }

    const [showList, setShowList] = useState(true)
    const [isClearAll, setIsClearAll] = useState(false)

    const restClearAllFilter = async () => {
        setIsClearAll(false)
    }

    const handleClose = () => {
        SetshowEdit(false)
    }

    return (
        <>
            {
                (showEdit) ? <EditPrescreenModal dataRowId={dataRowId} handleClose={handleClose} /> : null
            }

            {/* <ViewSection
                show={isViewModal}
                onHide={() => setIsViewModal(false)}
                id={rowId}
                data={rowData}
            /> */}
            {/* {
                (isProfileModal) ? <CandidateProfile canid={profileCanId} list={[]} isProfileModal={isProfileModal} handleCloseProfile={handleCloseProfile} /> : null
            } */}
            {
                showList
                    ?
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-md-12'>

                                <div className='dk-newprescrenformTableCon table-responsive'>
                                    <Table
                                        data={data}
                                        columns={columns}
                                        getInstanceCallback={reactTableInstance}
                                        loading={isLoading}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    : ''

            }
            <div className={`dk-smsMessage-selectMsg ${isActive ? "activePopup" : ""}`} style={{ maxWidth: "550px" }}>
                <div className="d-flex align-items-center justify-content-between">
                    {/* <div className="dk-selectNo">{countCheck}</div> */}
                    <div className="dk-selectNo">1</div>
                    {/*<div className="dk-rowSelected">
                        <span>Row Selected</span>
                    </div> */}

                    <div className="batch-actions-item dk-checkAll" onClick={() => editFunction(dataRowId)}>
                        <span onClick={() => editFunction(dataRowId)}><i className="far fa-edit"></i></span>
                        Edit
                    </div>
                    <div className="batch-actions-item dk-checkAll" data-moveto="Archived" onClick={moveRecords}>
                    <span data-moveto="Archived" onClick={moveRecords}>
                        <i className="fa fa-arrow-circle-left" data-moveto="Archived" onClick={moveRecords}></i>
                    </span>
                    Move to Archived
                    </div>
                                     
                    {/* <DeleteDummyModal /> */}
                    {/* <span><i className="far fa-trash-alt"></i></span>
                    Delete */}

                    <DeleteModal loading={loading} success={success} deleteItem={() => deleteItemBulk(dataRowId)} tableName="candidate_prescreening_forms" />
                    <div className="dk-checkAll d-flex align-items-center justify-content-center" onClick={handleToggle}>
                        <span><i className="fas fa-times"></i></span>
                    </div>
                </div>
            </div>

        </>
    )
}

export default ArchivedForm