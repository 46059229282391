import React, { useEffect, useState, memo } from "react";
import { Link } from "react-router-dom";
import { Tab, Nav } from "react-bootstrap";
// import "./payment-faqs.scss";
import FaqAccordion from "./Accordion";
import { useDispatch, useSelector } from "react-redux";

import {
  getdefaultQuesAns,
  // clearState,
  // getCompanyList,
  // getQuesAnsByCompanyId,
  getJobsFaqCategory,
  getQueAns,
  //getdefaultCategory,
  // getOnClickQuesAns,
} from "../../../../../../slice/faqs/jobsFaqSlice";

//import $ from "jquery";

const Overview = () => {
  const dispatch = useDispatch();
  const {
    isError,
    isSuccess,
    isLoading,
    // companiesList,
    JobsFaqCategoryList,
    jobsFaqQuestionAnsList,
    //isTabshow,
  } = useSelector((state) => state.jobsfaqData);
  const [activeLink, setActiveLink] = useState(null);
  const handleClick = async (id) => {
    //console.log(id);
    dispatch(getQueAns({ id }));
    setActiveLink(id);
  };

  useEffect(() => {
    dispatch(getJobsFaqCategory({}));
    dispatch(getdefaultQuesAns({}));
  }, []);

  useEffect(() => {
    if (
      !isLoading &&
      jobsFaqQuestionAnsList &&
      JobsFaqCategoryList &&
      JobsFaqCategoryList.length > 0
    ) {
      setActiveLink(JobsFaqCategoryList[0].id);
    }
  }, [isLoading]);

  return (
    <>
      <div className="dkg-payment-faq-pageCon">
        <div className="row dkg-candprescreen-mainRow">
          {/* <div className="col-md-10 dkg-cand-prescreen-centre-tabCon"> */}
          <div className="col-md-12 p-0 dkg-clientlist-faq-mainCon">
            <Tab.Container defaultActiveKey="question-pt-overview">
              <div className="row m-0 dkg-payment-faq-tab-mainCon">
                <div className="dkg-faq-catgory-activeTitle">
                  {/* {isCompanyName} */}
                </div>
                <div className="col-md-2 pl-0 dkg-payment-faq-tabCon dkg-faq-sideMenuCon-234">
                  <Nav
                    variant="pills"
                    className="flex-column dkg-payment-faq-tab-navpills"
                  >
                    {JobsFaqCategoryList.length > 0
                      ? JobsFaqCategoryList.filter(
                          (item) => item.status === "Active"
                        ).map((link, index) => {
                          return (
                            <Nav.Item className="dkg-payment-faq-tab-nav-item">
                              {
                                <Nav.Link
                                  to="#"
                                  key={link.id}
                                  data-id={link.id}
                                  onClick={() => handleClick(link.id)}
                                  className={
                                    activeLink === link.id
                                      ? "dkg-payment-faq-tab-navlink active"
                                      : "dkg-payment-faq-tab-navlink "
                                  }
                                >
                                  {link.name}
                                </Nav.Link>
                              }
                            </Nav.Item>
                          );
                        })
                      : null}
                  </Nav>
                </div>
                <div className="col-md-10 dkg-payment-faq-tabCon-10 dkg-faq-rightMenuCon-234 pr-0">
                  {jobsFaqQuestionAnsList.length > 0 ? (
                    <>
                      <Tab.Content className="dkg-payment-faq-tabcontent ddd">
                        <FaqAccordion quesAnswer={jobsFaqQuestionAnsList} />
                      </Tab.Content>
                    </>
                  ) : null}
                </div>
              </div>
            </Tab.Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(Overview);
