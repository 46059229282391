import React, { useEffect, useLayoutEffect, useState, memo } from "react";
import { Link } from "react-router-dom";
import { Tab, Nav } from "react-bootstrap";
import FaqAccordion from "./Accordion";
import { useDispatch, useSelector } from "react-redux";
import {
  getCategory,
  getQueAns,
  getOnClickQuesAns,
  clearState
} from "../../../../../../slice/faqs/newFaqSlice";
import $ from "jquery";

const Overview = ({ jobId, faqcat }) => {
  // console.log(faqcat)
  const dispatch = useDispatch();
  const {
    // isError,
    // isSuccess,
    // isLoading,
    // companiesList,
    categoryList,
    questionAnsList,
    //isTabshow,
    iscat,
    isQuesAns
  } = useSelector((state) => state.faqData);

  const [data, setData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [quesAnswer, setQuesAnswer] = useState([]);
  const [isCompanyName, isSetCompanyName] = useState("");
  //const { clientAllData } = useSelector((state) => state.jobsSpec);
  const [defaultActiveCompany, setDefaultActiveCompany] = useState(0);
  const [defaultCategoryActive, setDefaultCategoryActive] = useState(0);

  // useEffect(() => {
  //   dispatch(getCategory({ id: jobId }))
  // }, [jobId])

  useLayoutEffect(() => {
    if (!isQuesAns && questionAnsList && questionAnsList.length > 0) {
      setQuesAnswer(questionAnsList);
      setData(questionAnsList);
    }
    else {
      setQuesAnswer([])
    }

  }, [isQuesAns]);

  // useEffect(() => {
  //   if (isSuccess) {
  //     setData(questionAnsList);
  //   }
  // }, [isSuccess]);

  useLayoutEffect(() => {
    if (!iscat && categoryList && categoryList.length > 0) {
      setCategoryData(categoryList);
      setQuesAnswer(questionAnsList);
    }
    // else {
    //   setCategoryData([])
    //   setQuesAnswer([])
    // }
  }, [iscat]);

  const changeTabName = async (index, compid, comName) => {
    setDefaultCategoryActive(0);
    // setQuesAnswer([]);
    setDefaultActiveCompany(index);
    await dispatch(getCategory({ id: compid }));
    await dispatch(getOnClickQuesAns({ compid }));
    // isSetCompanyName("");
    isSetCompanyName(comName);
  };
  const changeCategoryTab = async (index, catid) => {
    setDefaultCategoryActive(catid);
    $(".removeActive").removeClass("active");
    $(".addActive" + catid).addClass("active");
    await dispatch(getOnClickQuesAns({ compid: catid }));
    await dispatch(getQueAns({ id: catid }));
  };

  return (
    <>
      <div className="dkg-payment-faq-pageCon">
        <div className="row dkg-candprescreen-mainRow">
          <div className="col-md-2 pl-0 dkg-cand-prescreen-faq-tabCon d-none">
            <Nav
              variant="pills"
              className="flex-column dkg-cand-prescreen-faq-tab-navpills"
            >
              {data.filter((item) => item.status === "Active")
                .map((comp, index) => {
                  return (
                    <Nav.Item
                      key={index}
                      className="dkg-cand-prescreen-faq-tab-nav-item cateNaam1"

                    >
                      <Link
                        to="#"
                        onClick={() =>
                          changeTabName(index, comp.id, comp.name)
                        }
                        className={
                          index === defaultActiveCompany
                            ? `dkg-cand-prescreen-faq-tab-navlink active`
                            : `dkg-cand-prescreen-faq-tab-navlink`
                        }
                      >
                        {comp.name}
                      </Link>
                    </Nav.Item>
                  );
                })}
            </Nav>
          </div>
          {/* <div className="col-md-10 dkg-cand-prescreen-centre-tabCon"> */}
          <div className="col-md-12 p-0 dkg-clientlist-faq-mainCon">
            <Tab.Container defaultActiveKey="question-pt-overview">
              <div className="row m-0 dkg-payment-faq-tab-mainCon">
                <div className="dkg-faq-catgory-activeTitle">
                  {isCompanyName}
                </div>
                <div className="col-md-2 pl-0 dkg-payment-faq-tabCon dkg-faq-sideMenuCon-234">
                  <Nav
                    variant="pills"
                    className="flex-column dkg-payment-faq-tab-navpills"
                  >
                    {
                      categoryData.filter((item) => item.status === "Active")
                        .map((cat, index) => {
                          return (
                            <Nav.Item className="dkg-payment-faq-tab-nav-item">
                              {
                                <Link
                                  to="#"
                                  onClick={() =>
                                    changeCategoryTab(index, cat.id)
                                  }
                                  className={
                                    index === defaultCategoryActive
                                      ? `dkg-payment-faq-tab-navlink removeActive active`
                                      : `dkg-payment-faq-tab-navlink removeActive addActive` +
                                      cat.id
                                  }
                                >
                                  {cat.name}
                                </Link>
                              }
                            </Nav.Item>
                          );
                        })
                    }
                  </Nav>
                </div>
                <div className="col-md-10 dkg-payment-faq-tabCon-10 dkg-faq-rightMenuCon-234 pr-0">
                  {
                    <Tab.Content className="dkg-payment-faq-tabcontent ddd">
                      <FaqAccordion faqdata={questionAnsList} />
                    </Tab.Content>
                  }

                </div>

              </div>
            </Tab.Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default Overview;
