import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './email-notifications.scss';
import { Modal, Nav, Tab } from 'react-bootstrap';
import { FaRegCalendarAlt, FaRegClock, FaPlus, FaEdit, FaTrashAlt, FaEye, } from 'react-icons/fa';
import { MdGridView } from "react-icons/md";
import ProfileImg from "../../../../../assets/images/default_profile.png";
import ReadMessageModal from './ReadMessage'
import EditModal from './modal/EditModal'
import { useDispatch, useSelector } from 'react-redux';
import { deleteInternalMailNotification, getInternalMailNotification, clearState} from '../../../../../slice/mailbox/mailboxSlice';
import { clearState as clearCandidateProfileInfo } from "../../../../../slice/candidates/list/listSlice";
import { showSuccess } from '../../../../utils/messages/messageSlice';
import { getNotificationCount } from '../../../../../slice/layout/layoutSlice';
import Submit from "../../../../ui/submitButton";
import CandidateProfile from "../../../../pages/candidate/pipeline/modal/candidateprofile";

const EnvelopeModal = () => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);

    const {isLoading, message, internalMailNotification, isInternalMailNotification, internalMailNotificationMessage} = useSelector((state) => state.mailbox);
    const {notificationCount} = useSelector((state) => state.common);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [isDeleteVisible, setIsDeleteVisible] = useState(false)
    const [deleteId, setDeleteId] = useState(0);

    useEffect(() => {
        if (show) {
            dispatch(getInternalMailNotification())
            if(internalMailNotificationMessage != null){
                dispatch(showSuccess({msg: internalMailNotificationMessage}))
                dispatch(getNotificationCount())
            }
          }else{
            dispatch(clearState());
          }
    },[show, internalMailNotificationMessage])

    const deleteNotification = (id) => {
        setIsDeleteVisible(true)
        setDeleteId(id)
    }

    const handleConfimationDeletePopup = () => {
        setIsDeleteVisible(false)
        setDeleteId(0)
    }

    const confirmDeleteNotification = () => {
        if (deleteId > 0) {
            dispatch(deleteInternalMailNotification({id: deleteId}))
            dispatch(getNotificationCount())
            setDeleteId(0)
            setIsDeleteVisible(false)
        }
    }

    const convertDate = (date) => {
        const originalDate = new Date(date);
        const options = {year: "2-digit", month: "short", day: "2-digit"};
        const formattedDate = new Intl.DateTimeFormat("en-IN", options).format(originalDate);
        return formattedDate; // Output: "14 Dec 23"
    };

    const convertTime = (date) => {
        const originalDate = new Date(date);
        const options = {hour: "numeric", minute: "numeric", hour12: true};
        const formattedTime = new Intl.DateTimeFormat("en-US", options).format(originalDate);
        return formattedTime;
    };

    const [canId, setCanId] = useState(0)
    const [isProfileModal, setIsProfileModal] = useState(false)

    const openCandidateProfile = (canId) => {
        setIsProfileModal(true);
        setCanId(canId)
    }

    const handleCloseProfile = () => {
        setIsProfileModal(false);
        setCanId(0)
        dispatch(clearCandidateProfileInfo())
    }

    return (
        <>
            <Link to="#" onClick={handleShow} className="dk-unreadEmails" title="Unread Message"><i className="fas fa-envelope"></i>
            {notificationCount > 0 ? 
                <span className="dk-unreadEmails-small">{notificationCount}</span>
            : ''}
            </Link>
            {
                (isProfileModal) ? <CandidateProfile canid={canId} list={[]} isProfileModal={isProfileModal} handleCloseProfile={handleCloseProfile} /> : null
            }
            <Modal className="dk-mlAddNewModal dk-deleteModal" show={isDeleteVisible} onHide={handleConfimationDeletePopup}>
                <Modal.Body className="dk-mlAddModalBody">
                    <p>Are you sure wants to Delete these records ?</p>
                    <div className="d-flex justify-content-end">
                        <button onClick={handleConfimationDeletePopup}>No</button>
                        <Submit txt="Yes" onClick={confirmDeleteNotification} position="justify-content-center" className="dk-yesBtn" />
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={show} onHide={handleClose} className="dk-candidate-emailModal">
                <Modal.Header closeButton className='dk-candidate-emailModalHeader'>
                    <Modal.Title>EMAILS NOTIFICATIONS</Modal.Title>
                </Modal.Header>
                <Modal.Body className='dk-candidate-emailModalBody'>
                    <div className='dkg-email-noti-tableCon'>
                        <table className='table table-bordered dkg-email-noti-table'>
                            <thead>
                                <tr>
                                    <th>From</th>
                                    <th>ID</th>
                                    <th>Subject</th>
                                    <th>Date</th>
                                    <th>Time</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {internalMailNotification.map((data, index) => (
                                <tr key={index}>
                                    <td>
                                        <div className="dk-notificationImg">
                                            {data?.candidate?.profileImage ? <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + data.candidate.profileImage} alt="" /> : <img src={ProfileImg} alt="" />}   
                                        </div>
                                    </td>
                                    <td>
                                        <span className='dkg-caseid-span' onClick={() => openCandidateProfile(data.candidate.CanId)}>{data.candidate.CanId}</span>
                                    </td>
                                    
                                    <td>
                                        <p className="dkg-env-readpopup">
                                            <ReadMessageModal data={data}/> 
                                        </p>
                                    </td>
                                    <td>
                                        <span className='dkg-datetime-span'>{convertDate(data.mailbox.createdAt)}</span>
                                    </td>
                                    <td>
                                        <span className='dkg-datetime-span'>{convertTime(data.mailbox.createdAt)}</span>
                                    </td>
                                    <td>
                                        <span className='dkg-envlope-action-cols'>
                                            <Link to="#" className="dkg-delete-icon" onClick={() => deleteNotification(data.id)}><FaTrashAlt /></Link>
                                        </span>
                                    </td>
                                </tr>
                                ))} 
                                {internalMailNotification.length == 0 ? 'No notification.' : ''}
                            </tbody>
                        </table>
                    </div> 
                </Modal.Body>
            </Modal>
        </>
    )
}

export default EnvelopeModal;