import React from 'react';
import { Modal } from 'react-bootstrap';
import "./viewpopup.scss";
import Submit from '../../../../../../../ui/submitButton'
import { FaRegSadTear } from "react-icons/fa";


const WithdrawApplication = ({ onHide }) => {

    return (
        <React.Fragment> 
            <Modal show={true} onHide={onHide} className="dkg-application-view-modal">
                 <Modal.Header closeButton className="dkg-app-widthraw-modal-header dkg-app-progress-modal-header">
                    <Modal.Title>Withdraw Job Application</Modal.Title>
                </Modal.Header>
                <Modal.Body className="dkg-app-widthraw-modal-body">
                    <div className="dkg-app-widthraw-desc-con">
                        <h2 className="dkg-app-withdraw-title">We are sorry that you do not wish to continue <span className="dkg-withdraw-emoji"><FaRegSadTear /></span></h2>
                        <p className="dkg-app-widthraw-desc">
                            Please select the reasons below for your decision.
                            {/* This will help us to contact you. back in future with better job proposals. 
                             if you give us the reason, 
                            then we will stop contacting you with immedidate effect
                            otherwise we assure that you are still interested this job and we must stay connected. */}
                        </p>
                    </div>  
                    <div className="dkg-app-withdraw-ulCon">
                        <ul className="dkg-app-withdraw-ul">
                            <li className="dkg-app-withdraw-li">
                                <span className='dkg-app-withdraw-checkbox'>
                                    <input  type='checkbox' className='form-control dkg-appwithdraw-checkbox' name='job-reason-checkbox'/>
                                </span>
                                <p className='dkg-app-withdraw-reson'>I have changed my interest in this job.</p>
                            </li>
                            <li className="dkg-app-withdraw-li">
                                <span className='dkg-app-withdraw-checkbox'>
                                    <input  type='checkbox' className='form-control dkg-appwithdraw-checkbox' name='job-reason-checkbox'/>
                                </span>
                                <p className='dkg-app-withdraw-reson'>I do not like the salary and benefits package anymore.</p>
                            </li>
                            <li className="dkg-app-withdraw-li">
                                <span className='dkg-app-withdraw-checkbox'>
                                    <input  type='checkbox' className='form-control dkg-appwithdraw-checkbox' name='job-reason-checkbox'/>
                                </span>
                                <p className='dkg-app-withdraw-reson'>I am already taking interviews with this hiring company.</p>
                            </li>
                            <li className="dkg-app-withdraw-li">
                                <span className='dkg-app-withdraw-checkbox'>
                                    <input  type='checkbox' className='form-control dkg-appwithdraw-checkbox' name='job-reason-checkbox'/>
                                </span>
                                <p className='dkg-app-withdraw-reson'>I have received another job offer from another employer.</p>
                            </li>
                            <li className="dkg-app-withdraw-li">
                                <span className='dkg-app-withdraw-checkbox'>
                                    <input  type='checkbox' className='form-control dkg-appwithdraw-checkbox' name='job-reason-checkbox'/>
                                </span>
                                <p className='dkg-app-withdraw-reson'>I am not happy with the recruitment process by DK Global.</p>
                            </li>
                            <li className="dkg-app-withdraw-li">
                                <span className='dkg-app-withdraw-checkbox'>
                                    <input  type='checkbox' className='form-control dkg-appwithdraw-checkbox' name='job-reason-checkbox'/>
                                </span>
                                <p className='dkg-app-withdraw-reson'>I am not happy with the recruitment process by the employer.</p>
                            </li>
                            <li className="dkg-app-withdraw-li">
                                <span className='dkg-app-withdraw-checkbox'>
                                    <input  type='checkbox' className='form-control dkg-appwithdraw-checkbox' name='job-reason-checkbox'/>
                                </span>
                                <p className='dkg-app-withdraw-reson'>I have applied this job by mistake and i do not wish to continue.</p>
                            </li>
                        </ul>
                        <div className="dkg-appwithdraw-textarea-Con">
                            <textarea name="appwithdraw-textarea"  className="dkg-appwithdraw-textarea" placeholder='Any other specific reason please mention here' />
                        </div>
                    </div>     
                    <div className="dkg-appwithdraw-btnCon">
                        <Submit txt="SAVE" className="dkg-appwithdraw-btn"/>
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}

export default WithdrawApplication;
