import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import thunk from "redux-thunk";
import storage from "redux-persist/lib/storage";

import ProgressBarReducer from "../slice/utils/loader/linearSlice";
import alertMessagesReducer from "../slice/utils/message/messageSlice";
import authReducer from "../slice/auth/authSlice";
import moduleReducer from "../slice/modules/moduleSlice";
import layoutReducer from "../slice/layout/layoutSlice";
import userListReducer from "../slice/userList/userSlice";

import precreeningReducer from "../slice/account/precreeningForm/precreeningSlice";
import candidatePrecreeningReducer from "../slice/candidatePrecreeningForm/candidatePrecreeningSlice";
import notesReducer from "../slice/account/notes/notesSlice";
import todoReducer from "../slice/account/todo/todoSlice";
import tokenReducer from "../slice/auth/tokenSlice";
import tasksReducer from "../slice/calendar/tasks/taskSlice";
import teamReducer from "../slice/dkgTeam/teamSlice";
import faqReducer from "../slice/faqs/faqSlice";
import newFaqReducer from "../slice/faqs/newFaqSlice";
import jobsfaqDataReducer from "../slice/faqs/jobsFaqSlice";
import candidatefaqDataReducer from "../slice/faqs/candidateFaqSlice";
import getStartReducer from "../slice/getStart/getStartSlice";
import mailshotsReducer from "../slice/mailshots/mailshotsSlice";
import jobProfileReducer from "../slice/jobProfile/jobProfileSlice";
import campaignReducer from "../slice/campaign/campaignSlice";
import qualifyReducer from "../slice/qualify/qualifySlice";
import smsTemplateReducer from "../slice/sms/templateSlice";
import smsReducer from "../slice/sms/smsSlice";
import appsTrackerReducer from "../slice/trackers/appsTracker/appsTracker";
import clientKpiReducer from "../slice/clientKpi/clientKpiSlice";
import sendoutKpiReducer from "../slice/kpis/sendoutKpiSlice";
import sendoutBonusReducer from "../slice/kpis/sendoutBonusSlice";
import businessSavingReducer from "../slice/businessSaving/businessSlice";
import todaysAdsTrackerReducer from "../slice/ads/todaysAds/todaysAdsSlice";
import liveAdsTrackerReducer from "../slice/ads/liveAds/liveAdsSlice";
import backendValuesReducer from "../slice/backendValues/backendValuesSlice";
import companiesReducer from "../slice/companies/clients/clientSlice";
import candidateReducer from "../slice/candidates/candidatesSlice";
import candidatesNewCVReducer from "../slice/candidates/list/newCvSlice";
import commentReducer from "../slice/comments/commentSlice";
import mlResourceReducer from "../slice/resources/MlResourcesSlice";
import itResourceReducer from "../slice/resources/ItResourcesSlice";
import loginsResourceReducer from "../slice/resources/Logins";
import kpiTeamSaleReducer from "../slice/kpis/teamKpiSlice";
import mailboxReducer from "../slice/mailbox/mailboxSlice";
import placementsBonusReducer from "../slice/kpis/placementsBonusSlice";
import perfornanceBonusReducer from "../slice/kpis/performanceBonusSlice";
import salesBooksReducer from "../slice/accounting/salesBooks";
import invoiceBooksReducer from "../slice/accounting/invoiceBooks";
import bankingBooksReducer from "../slice/accounting/accountBanking";
import cashflowdatasReducer from "../slice/accounting/cashflow";
import accountJobofferReducer from "../slice/accounting/joboffer";
import accountJoinedReducer from "../slice/accounting/joined";
import accountBusinessbookReducer from "../slice/accounting/businessBookSlice";

//** Jobs start */
import jobsReducer from "../slice/jobs/jobsSlice";
import inactivejobsReducer from "../slice/jobs/inactiveJobsSlice";
import jobsSummaryReducer from "../slice/jobs/summary/summarySlice";
//** jobs end  */

//** JobsSpec start */
import jobSpecReducer from "../slice/jobSpec/jobsSlice";
//** JobsSpec start */
import jobInterviewSpecReducer from "../slice/interviewSpec/jobsSlice";
import jobOfferSpecReducer from "../slice/offerSpec/jobsSlice";
//** JobsSpec end  */
import candidateDatabaseReducer from "../slice/candidates/database/databaseSlice";
import projectsReducer from "../slice/projects/projctsSlice";
import searchCVReducer from "../slice/searchCV/searchCvSlice";

/** Pipeline List/ CandidateProfile */
import candidatesReducer from "../slice/candidates/list/listSlice";
import taskReducer from "../slice/task/taskSlice";
import paymentTaskReducer from "../slice/task/paymentTaskSlice";
import assignKpiReducer from "../slice/kpis/assignKpisReportSlice";
import qualifiedKpiReducer from "../slice/kpis/qualifiedKpisReportSlice";
import reportKpiReducer from "../slice/kpis/reportKpiReportSlice";
import sendoutKKpiReducer from "../slice/kpis/sendoutKpiReportSlice";
import refusedKpiReducer from "../slice/kpis/refusedKpiReportSlice";
import jobofferKpiReducer from "../slice/kpis/joboffersKpiReportSlice";
import joinedKpiReducer from "../slice/kpis/joinedKpiReportSlice";
import creditKpiReducer from "../slice/kpis/creditKpisReportSlice";
import rcSummaryKpiReducer from "../slice/kpis/recruterSummarySlice";
import advPerformanceReducer from "../slice/costBenifits/advPerformance";
import advBudgetsdataReducer from "../slice/costBenifits/advBudgets";
import scriptsReducer from "../slice/candidatePrecreeningForm/scripts";
import demandsReducer from "../slice/demands/demandSlice";
import requirnmentReducer from "../slice/requirnments/requirnmentSlice";
import clientprojectReducer from "../slice/clientProject/clientprojectSlice";
import clientrolestReducer from "../slice/clientRolet/clientrolet";
import clientlangReducer from "../slice/clientlang/clientlang";
import clienteuropReducer from "../slice/clientEurop/clientErop";
import processReducer from "../slice/commonTraining/process/processSlice";
import principalsReducer from "../slice/principals/principalsSlice";
import solutionSliceReducer from "../slice/solutions/solutionSlice";
import teaMembersSliceReducer from "../slice/teams/teamMembersSlice";
import whatisSliceReducer from "../slice/whatis/whatisSlice";
import clientRoleTypeSliceReducer from "../slice/clientRoleType";
import attendanceReducer from "../slice/attendance/attendanceSlice";
import clientpoartlsSlice from "../slice/clientPoartls";
import clientSendoutSlice from "../slice/clientSendout";
import clientInterviewSlice from "../slice/interviews";
import clientJobofferSlice from "../slice/jobOffers";
import clientRelocationSlice from "../slice/relocations";
import clientPortalsSlice from "../slice/portals";
import jobsSpecSlice from "../slice/jobSpec/jobSpecSlice";
import cmslistReducer from "../slice/frontendCms/frontendCmsSlice";
import cmsReducer from "../slice/cmsSlice";
import clientJoinningReducer from "../slice/clientJoinning/clientJoinningSlice";

const reducers = combineReducers({
  progressBar: ProgressBarReducer,
  alertMessage: alertMessagesReducer,
  auth: authReducer,
  module: moduleReducer,
  common: layoutReducer,
  users: userListReducer,
  candidate: candidateReducer,
  candidatesNewCV: candidatesNewCVReducer,
  comments: commentReducer,

  jobs: jobsReducer,
  inactivejobs: inactivejobsReducer,
  jobsSummray: jobsSummaryReducer,

  jobSpec: jobSpecReducer,
  interviewSpec: jobInterviewSpecReducer,
  offerSpec: jobOfferSpecReducer,

  precreening: precreeningReducer,
  candidatePrecreening: candidatePrecreeningReducer,
  notes: notesReducer,
  todo: todoReducer,
  token: tokenReducer,
  tasks: tasksReducer,
  teamData: teamReducer,
  faq: faqReducer,
  faqData: newFaqReducer,
  jobsfaqData: jobsfaqDataReducer,
  candidatefaqData: candidatefaqDataReducer,
  getStart: getStartReducer,
  mailshots: mailshotsReducer,
  jobProfile: jobProfileReducer,
  campaignAds: campaignReducer,
  qualify: qualifyReducer,
  smsTemplate: smsTemplateReducer,
  sms: smsReducer,
  appsTracker: appsTrackerReducer,
  clientKpi: clientKpiReducer,
  sendoutKpi: sendoutKpiReducer,
  sendoutBonus: sendoutBonusReducer,
  business: businessSavingReducer,
  todaysAdsTracker: todaysAdsTrackerReducer,
  liveAdsTracker: liveAdsTrackerReducer,
  backendValues: backendValuesReducer,
  bkValuesCategory: backendValuesReducer,
  companies: companiesReducer,
  mlResource: mlResourceReducer,
  itResource: itResourceReducer,
  loginsResource: loginsResourceReducer,

  kpiTeam: kpiTeamSaleReducer,
  mailbox: mailboxReducer,
  candidateDatabase: candidateDatabaseReducer,
  projects: projectsReducer,
  searchCV: searchCVReducer,

  candidates: candidatesReducer,
  task: taskReducer,
  paymentTask: paymentTaskReducer,
  assignKpiReport: assignKpiReducer,
  qualifiedKpiReport: qualifiedKpiReducer,
  reportKpiReport: reportKpiReducer,
  sendoutKpiReport: sendoutKKpiReducer,
  refusedKpiReport: refusedKpiReducer,
  jobofferKpiReport: jobofferKpiReducer,
  joinedKpiReport: joinedKpiReducer,
  creditKpiReport: creditKpiReducer,
  recruterKpiSummary: rcSummaryKpiReducer,
  placementsBonus: placementsBonusReducer,
  perfornanceBonus: perfornanceBonusReducer,
  salesBooks: salesBooksReducer,
  invoiceBooks: invoiceBooksReducer,
  bankingBooks: bankingBooksReducer,
  cashflowdata: cashflowdatasReducer,
  performancedata: advPerformanceReducer,
  budgetsdata: advBudgetsdataReducer,
  accountJoboffer: accountJobofferReducer,
  accountJoined: accountJoinedReducer,
  businessBook: accountBusinessbookReducer,
  screeningFormScript: scriptsReducer,
  demands: demandsReducer,
  reqirnments: requirnmentReducer,
  clientproject: clientprojectReducer,
  clientrolest: clientrolestReducer,
  clientlang: clientlangReducer,
  clienteurop: clienteuropReducer,
  process: processReducer,
  principals: principalsReducer,
  solutions: solutionSliceReducer,
  teammembers: teaMembersSliceReducer,
  whatis: whatisSliceReducer,
  clientroletype: clientRoleTypeSliceReducer,
  attendance: attendanceReducer,
  clientpoartls: clientpoartlsSlice,
  clientsendout: clientSendoutSlice,
  clientInterview: clientInterviewSlice,
  clientJoboffer: clientJobofferSlice,
  clientRelocation: clientRelocationSlice,
  clientPortals: clientPortalsSlice,
  jobsSpec: jobsSpecSlice,
  cmslist: cmslistReducer,
  cmsdata: cmsReducer,
  clientJoining: clientJoinningReducer,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth", "module", "task"],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk],
});
