import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import {
  FaArrowRight,
  FaEye,
  FaTrashAlt,
  FaUser,
  FaTimes,
  FaCheck,
} from "react-icons/fa";
import ViewProgressModal from "./modal/ViewProgress";
import WithdrawApplication from "./modal/WithdrawApplication";
import StatusDopdown from "./dropdown/StatusDopdown";
import { useDispatch, useSelector } from "react-redux";
// import SelectinputDropDown from './dropdown/SelectinputDropDown'
import CommentModal from "../../../../../../utils/comment/profileComment";
// import Candidate from "../../../../../../../assets/images/ajay.jpeg";
import AppcardDeleteModal from "../.././../../../../ui/Modal/deletemodal/DeleteModal";
import UserList from "../../../../../../ui/userList/UserListByRoleForCandidates";
import {
  updateApplication,
  deleteCanApplication,
} from "../../../../../../../slice/candidates/list/listSlice";
import CompanyDropdown from "../../../../../jobs/addnew/CompanyCanApp";
import SearchDropDownFilter from "../../../../../jobs/addnew/OthersCanApp";
// import {
//   companyData,
//   companyProjectData,
//   companyRoleData,
// } from "../../../../../../../slice/companies/clients/clientSlice";

const ApplicationTable = ({ id, removeDiv, itemdata }) => {
  const dispatch = useDispatch();
  const { isAppDelete } = useSelector((state) => state.candidates);

  const { companyList, companyProjtList, companyRolList } = useSelector(
    (state) => state.companies
  );

  const {
    // jobsCategoryList,
    // jobsCompanyList,
    jobsProjectList,
    jobsRoleTypeList,
    jobsLanguageList,
  } = useSelector((state) => state.common);

  const [isRefresh, setIsRefresh] = useState(false);

  const [isDeleteAppModal, setIsDeleteAppModal] = useState(false);
  const [isViewProgress, setIsViewProgress] = useState(false);
  const [isWithdrawApp, setIsWithdrawApp] = useState(false);
  const [isId, setIsId] = useState(0);
  const [companydata, setCompanydata] = useState(
    companyList.filter((item) => item.inStatus === 1)
  );

  const showDeleteAppModal = (id) => {
    setIsId(id);
    setIsDeleteAppModal(true);
  };
  const hideDeleteAppModal = () => {
    setIsDeleteAppModal(false);
  };
  const showViewProgress = () => {
    setIsViewProgress(true);
  };
  const hideViewProgress = () => {
    setIsViewProgress(false);
  };
  const showWithdrawModal = () => {
    setIsWithdrawApp(true);
  };
  const hideWithdrawModal = () => {
    setIsWithdrawApp(false);
  };

  //const [caseStatus, setCaseStatus] = useState("");
  const [newstatusList, setNewstatusList] = useState([]);
  const [newCaseStatus, setNewCaseStatus] = useState("Open");
  const [newSubmitted, setNewSubmitted] = useState("Submitted");
  const [newUnderReview, setNewUnderReview] = useState("Under Review");

  /******comment popup start**** */
  const [refTable, setRefTable] = useState("");
  const [refId, setRefId] = useState();
  const [commentsCount, setCommentCount] = useState(0);
  const [title, setTitle] = useState("Application Progress Flow");
  const [newStatus, setNewStatus] = useState("");
  const [newProgess, setNewProgess] = useState("");
  const [newStage, setNewStage] = useState("");
  const [candidateStatus, setCandidteStatus] = useState("");
  const [candidateProgess, setCandidateProgess] = useState("");
  const [candidateStage, setCandidateStage] = useState("");
  const [commentPopupShow, setCommentPopupShow] = useState(false);
  /******comment popup end**** */
  const commentPoupShow = (e) => {
    // setRefId(cell.row.original.canId);
    // setCommentCount(cell.row.original.commentCount);
    // setCandidteStatus(cell.row.original.caseStatus);
    // setCandidateProgess(cell.row.original.case_progress);
    // setCandidateStage(cell.row.original.case_stage);
    setCommentPopupShow(true);
  };
  const commentHandleClose = () => {
    // setNewStatus("");
    // setNewProgess("");
    // setNewStage("");
    setCommentPopupShow(false);
  };

  const onItemClick = (id, columnId, value, img) => {
    dispatch(
      updateApplication({ id, key: columnId, value, canId: itemdata.canId })
    );
  };
  useEffect(() => {
    if (isAppDelete) {
      setIsDeleteAppModal(false);
    }
  }, [isAppDelete]);

  const onJobsTitle = (id, columnId, datatype) => {
    dispatch(
      updateApplication({
        id,
        key: columnId,
        value: datatype,
        canId: itemdata.canId,
      })
    );
  };

  const getCompanyValue = (value, id) => {
    dispatch(
      updateApplication({
        id,
        key: "company",
        value,
        canId: itemdata.canId,
      })
    );
  };

  const getProjectValue = (value, id) => {
    dispatch(
      updateApplication({
        id,
        key: "project",
        value,
        canId: itemdata.canId,
      })
    );
  };
  const getRoleTypetValue = (value, id) => {
    dispatch(
      updateApplication({
        id,
        key: "role",
        value,
        canId: itemdata.canId,
      })
    );
  };
  const getLanguagetValue = (value, id) => {
    dispatch(
      updateApplication({
        id,
        key: "language",
        value,
        canId: itemdata.canId,
      })
    );
  };

  const deleteHandler = () => {
    dispatch(
      deleteCanApplication({
        id: isId,
      })
    );
  };

  return (
    <>
      {isViewProgress ? <ViewProgressModal onHide={hideViewProgress} /> : null}
      {isWithdrawApp ? (
        <WithdrawApplication onHide={hideWithdrawModal} />
      ) : null}
      {isDeleteAppModal ? (
        <AppcardDeleteModal
          handleClose={hideDeleteAppModal}
          deleteItem={deleteHandler}
        />
      ) : null}
      {commentPopupShow ? (
        <CommentModal
          refTable={refTable}
          refId={refId}
          count={commentsCount}
          title={title}
          closedCommentPopup={commentHandleClose}
          candidateStatus={newStatus != "" ? newStatus : candidateStatus}
          candidateProgess={newProgess != "" ? newProgess : candidateProgess}
          candidateStage={newStage != "" ? newStage : candidateStage}
        />
      ) : null}

      <tbody>
        <tr>
          <td>
            <div className="dkg-app-user-dropdrownCon">
              <Dropdown className="dkg-app-user-dropdrown">
                <Dropdown.Toggle
                  variant=""
                  id=""
                  className="dkg-app-user-dropdrown-toggle"
                >
                  <div className="tooltipMain">
                    <img
                      src={
                        process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                        itemdata.recInfo.profileImage
                      }
                      className="filterImg"
                    />
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu className="dkg-app-user-dropdrown-menu">
                  <div className="d-flex">
                    <UserList
                      id={itemdata.id}
                      columnId="rcId"
                      onItemClick={onItemClick}
                    />
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </td>
          <td>
            <div className="dkg-job-title-cols">
              <div
                className="dkg-job-title"
                style={{ textTransform: "uppercase" }}
              >
                Case Status
              </div>
              <span className="dkg-arrow-right">
                <FaArrowRight />
              </span>
            </div>
          </td>
          <td>
            <StatusDopdown caseStatus={newCaseStatus} list={newstatusList} />
          </td>
          <td colSpan={2}>
             <div className="dkg-app-st-status-dropdwnCon d-flex w-100">
              <div className="dkg-app-st-status-dropdwn" style={{borderRight: "1px solid #ddd"}}>
                <StatusDopdown caseStatus={newSubmitted} list={newstatusList} />
              </div>
              <div className="dkg-app-st-status-dropdwn" style={{borderRight: "1px solid #ddd"}}>
                <StatusDopdown caseStatus={newUnderReview} list={newstatusList} />
              </div>
              <div className="dkg-app-st-status-dropdwn">
                <StatusDopdown caseStatus={newUnderReview} list={newstatusList} />
              </div>
             </div>
          </td>
          <td className="dkg-aapiled-app-td" title="Last Status Updated">
            <Link
              to="#"
              className="dkg-app-viewjob-btn"
              style={{ backgroundColor: "#3c97b6", color: "#fff" }}
            >
              25 May 2024
            </Link>
          </td>
        </tr>
        <tr>
          <td>
            <div className="dkg-app-linked-dropdownCon">
              <Dropdown className="dkg-app-linked-dropdown">
                <Dropdown.Toggle
                  variant=""
                  className="dkg-app-linked-dropdown-toggle"
                >
                  <div className="dkg-svg-icon">
                    {itemdata.isJobTitle === 1 ? <FaCheck /> : <FaTimes />}
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu className="dkg-app-linked-dropdownMenu">
                  <Dropdown.Item
                    href="#"
                    className="dkg-linked-dropdown-iten"
                    onClick={(e) => onJobsTitle(itemdata.id, "isJobTitle", 1)}
                  >
                    <FaCheck />
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#"
                    className="dkg-linked-dropdown-iten"
                    onClick={(e) => onJobsTitle(itemdata.id, "isJobTitle", 0)}
                  >
                    <FaTimes />
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </td>
          <td>
            <div className="dkg-job-title-cols">
              <div className="dkg-job-title">JOB INFO</div>
              <span className="dkg-arrow-right">
                <FaArrowRight />
              </span>
            </div>
          </td>
          <td>
          <div className="dkg-app-select-dropdown-Con">
              <Dropdown className="dkg-app-select-dropdown">
                <Dropdown.Toggle
                  variant=""
                  id=""
                  className="dkg-app-select-dropdown-toggle dkg-blue-jobtitle"
                >
                  Link
                </Dropdown.Toggle>
                <Dropdown.Menu className="dkg-app-select-dropdown-menu">
                  <Dropdown.Item
                    href="#"
                    className="dkg-app-select-dropdown-item"
                  >
                    Link 1
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#"
                    className="dkg-app-select-dropdown-item"
                  >
                    Link 2
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#"
                    className="dkg-app-select-dropdown-item"
                  >
                    Link 3
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </td>
          <td colSpan={2}>
            <span className="dkg-blue-jobtitle" style={{color: "#2e75b6"}}>German speacking customer support role in Greece</span>
            {/* <div className="dkg-app-select-dropdown-Con">
              <Dropdown className="dkg-app-select-dropdown">
                <Dropdown.Toggle
                  variant=""
                  id=""
                  className="dkg-app-select-dropdown-toggle dkg-blue-jobtitle"
                >
                  German speacking customer support role in Greece
                </Dropdown.Toggle>
                <Dropdown.Menu className="dkg-app-select-dropdown-menu">
                  <Dropdown.Item
                    href="#"
                    className="dkg-app-select-dropdown-item"
                  >
                    German speacking customer support role in Greece 1
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#"
                    className="dkg-app-select-dropdown-item"
                  >
                    German speacking customer support role in Greece 2
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#"
                    className="dkg-app-select-dropdown-item"
                  >
                    German speacking customer support role in Greece 3
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div> */}
          </td>
          <td className="dkg-aapiled-app-td" onClick={commentPoupShow}>
            <Link
              to="#"
              className="dkg-app-viewjob-btn"
              style={{ backgroundColor: "#3c97b6", color: "#fff" }}
            >
              {/* <span className="dkg-eye-icon mr-2">
                <FaEye />
              </span> */}
              Case Progress
            </Link>
          </td>
        </tr>
        <tr>
          <td>
            <div className="dkg-app-linked-dropdownCon">
              <Dropdown className="dkg-app-linked-dropdown">
                <Dropdown.Toggle
                  variant=""
                  className="dkg-app-linked-dropdown-toggle"
                >
                  <div className="dkg-svg-icon">
                    {itemdata.isJobSpec === 1 ? <FaCheck /> : <FaTimes />}
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu className="dkg-app-linked-dropdownMenu">
                  <Dropdown.Item
                    href="#"
                    className="dkg-linked-dropdown-iten"
                    onClick={(e) => onJobsTitle(itemdata.id, "isJobSpec", 1)}
                  >
                    <FaCheck />
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#"
                    className="dkg-linked-dropdown-iten"
                    onClick={(e) => onJobsTitle(itemdata.id, "isJobSpec", 0)}
                  >
                    <FaTimes />
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </td>
          <td>
            <div className="dkg-job-title-cols">
              <div className="dkg-job-title">JOB SPEC</div>
              <span className="dkg-arrow-right">
                <FaArrowRight />
              </span>
            </div>
          </td>
          <td>
          <div className="dkg-app-select-dropdown-Con">
              <Dropdown className="dkg-app-select-dropdown">
                <Dropdown.Toggle
                  variant=""
                  id=""
                  className="dkg-app-select-dropdown-toggle dkg-blue-jobtitle"
                >
                  Link
                </Dropdown.Toggle>
                <Dropdown.Menu className="dkg-app-select-dropdown-menu">
                  <Dropdown.Item
                    href="#"
                    className="dkg-app-select-dropdown-item"
                  >
                    Link 1
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#"
                    className="dkg-app-select-dropdown-item"
                  >
                    Link 2
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#"
                    className="dkg-app-select-dropdown-item"
                  >
                    Link 3
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </td>
          <td colSpan={2}>
            <span className="dkg-orange-jobtitle"  style={{color: "#d58b5d"}}>German speacking customer support role in Greece</span>
            {/* <div className="dkg-app-select-dropdown-Con">
              <Dropdown className="dkg-app-select-dropdown" disabled>
                <Dropdown.Toggle
                  variant=""
                  id=""
                  className="dkg-app-select-dropdown-toggle dkg-orange-jobtitle"
                >
                  German speacking customer support role in Greece
                </Dropdown.Toggle>
                <Dropdown.Menu className="dkg-app-select-dropdown-menu">
                  <Dropdown.Item
                    href="#"
                    className="dkg-app-select-dropdown-item"
                  >
                    German speacking customer support role in Greece 1
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#"
                    className="dkg-app-select-dropdown-item"
                  >
                    German speacking customer support role in Greece 2
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#"
                    className="dkg-app-select-dropdown-item"
                  >
                    German speacking customer support role in Greece 3
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div> */}
          </td>
          <td className="dkg-aapiled-app-td">
            <Link
              to="#"
              className="dkg-app-viewspec-btn"
              style={{ backgroundColor: "#3c97b6", color: "#fff" }}
            >
              {/* <span className="dkg-eye-icon mr-2">
                <FaEye />
              </span> */}
              Job Application
            </Link>
          </td>
        </tr>
        <tr>
          <td>
            <div className="dkg-appied-action-cols">
              {/* <div className="dkg-appied-edit-cols">
                            <FaEdit />
                        </div> */}
              <div
                className="dkg-appied-cross-cols"
                onClick={() => showDeleteAppModal(itemdata.id)}
              >
                <FaTrashAlt />
              </div>
            </div>
          </td>
          <td className="p-0">
            {/* <div className="dkg-job-title-cols">
              <div className="dkg-job-title">JOB INFO</div>
              <span className="dkg-arrow-right">
                <FaArrowRight />
              </span>
            </div> */}
             <div className="dkg-app-category-dropdown-Con">
              <Dropdown className="dkg-app-category-dropdown">
                <Dropdown.Toggle
                  variant=""
                  id=""
                  className="dkg-app-category-dropdown-toggle"
                >
                  Category 
                </Dropdown.Toggle>
                <Dropdown.Menu className="dkg-app-category-dropdown-menu">
                  <Dropdown.Item
                    href="#"
                    className="dkg-app-category-dropdown-item"
                  >
                    IT
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#"
                    className="dkg-app-category-dropdown-item"
                  >
                    ML
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </td>
          <td colSpan={3}>
            <div className="dkg-app-jobinfo-four-cols">
              <div className="dkg-app-jobinfo-cols">
                {/* <SelectinputDropDown /> */}
                <div className="dkg-app-select-dropdown-Con">
                  {/* <Dropdown className="dkg-app-select-dropdown">
                    <Dropdown.Toggle
                      variant=""
                      id=""
                      className="dkg-app-select-dropdown-toggle"
                    >
                      Teleperformance
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dkg-app-select-dropdown-menu">
                      <Dropdown.Item
                        href="#"
                        className="dkg-app-select-dropdown-item"
                      >
                        Teleperformance 1
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#"
                        className="dkg-app-select-dropdown-item"
                      >
                        Teleperformance 2
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#"
                        className="dkg-app-select-dropdown-item"
                      >
                        Teleperformance 3
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown> */}
                  <CompanyDropdown
                    categoryList={companydata}
                    catList={getCompanyValue}
                    isRefresh={isRefresh}
                    id={itemdata.id}
                    dataValue={itemdata.company}
                  />
                </div>
              </div>
              <div className="dkg-app-jobinfo-cols">
                <div className="dkg-app-select-dropdown-Con">
                  {/* <Dropdown className="dkg-app-select-dropdown">
                    <Dropdown.Toggle
                      variant=""
                      id=""
                      className="dkg-app-select-dropdown-toggle"
                    >
                      Apple Care
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dkg-app-select-dropdown-menu">
                      <Dropdown.Item
                        href="#"
                        className="dkg-app-select-dropdown-item"
                      >
                        Teleperformance 1
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#"
                        className="dkg-app-select-dropdown-item"
                      >
                        Teleperformance 2
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#"
                        className="dkg-app-select-dropdown-item"
                      >
                        Teleperformance 3
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown> */}
                  <SearchDropDownFilter
                    categoryList={jobsProjectList}
                    catList={getProjectValue}
                    isRefresh={isRefresh}
                    id={itemdata.id}
                    dataValue={itemdata.project}
                  />
                </div>
              </div>
              <div className="dkg-app-jobinfo-cols">
                <div className="dkg-app-select-dropdown-Con">
                  {/* <Dropdown className="dkg-app-select-dropdown">
                    <Dropdown.Toggle
                      variant=""
                      id=""
                      className="dkg-app-select-dropdown-toggle"
                    >
                      1st Line ITS
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dkg-app-select-dropdown-menu">
                      <Dropdown.Item
                        href="#"
                        className="dkg-app-select-dropdown-item"
                      >
                        1st Line ITS 1
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#"
                        className="dkg-app-select-dropdown-item"
                      >
                        1st Line ITS 2
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#"
                        className="dkg-app-select-dropdown-item"
                      >
                        1st Line ITS 3
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown> */}
                  <SearchDropDownFilter
                    categoryList={jobsRoleTypeList}
                    catList={getRoleTypetValue}
                    isRefresh={isRefresh}
                    id={itemdata.id}
                    dataValue={itemdata.role}
                  />
                </div>
              </div>
              <div className="dkg-app-jobinfo-cols">
                <span className="dkg-jobinfo-title"></span>
                <div className="dkg-app-select-dropdown-Con">
                  {/* <Dropdown className="dkg-app-select-dropdown">
                    <Dropdown.Toggle
                      variant=""
                      id=""
                      className="dkg-app-select-dropdown-toggle"
                    >
                      German Fluent
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dkg-app-select-dropdown-menu">
                      <Dropdown.Item
                        href="#"
                        className="dkg-app-select-dropdown-item"
                      >
                        German Fluent 1
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#"
                        className="dkg-app-select-dropdown-item"
                      >
                        German Fluent 2
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#"
                        className="dkg-app-select-dropdown-item"
                      >
                        German Fluent 3
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown> */}
                  <SearchDropDownFilter
                    categoryList={jobsLanguageList}
                    catList={getLanguagetValue}
                    isRefresh={isRefresh}
                    id={itemdata.id}
                    dataValue={itemdata.language}
                  />
                </div>
              </div>
            </div>
          </td>
          <td className="dkg-aapiled-app-td" onClick={showWithdrawModal}>
            <Link
              to="#"
              className="dkg-withdrawl-app-btn"
              style={{ backgroundColor: "#CA464F", color: "#fff" }}
            >
              Withdraw Application{" "}
            </Link>
          </td>
        </tr>
      </tbody>
    </>
  );
};

export default ApplicationTable;
