import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/modal";
import { FaUserAlt } from "react-icons/fa";
import "./jobs-faqmodal.scss";
import FaqOverview from "./faq/Overview";

import {
  // getdefaultQuesAns,
  // clearState,
  // getCompanyList,
  // getQuesAnsByCompanyId,
  getJobsFaqCategory,
  getQueAns,
  //getdefaultCategory,
  getOnClickQuesAns,
} from "../../../../../slice/faqs/jobsFaqSlice";

const JobsFaqPopup = () => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);

  const handleClose = () => setShow(false);
  useEffect(() => {
    dispatch(getJobsFaqCategory({}));
  }, []);

  return (
    <>
      <Link to="#" className="dkg-jobsfaq-tab" onClick={handleShow}>
        JOBS FAQ’s{" "}
      </Link>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="dk-candidateProfile80 dkg-jobsfaq-popupMain"
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton className="dk-candModalHeader">
          <Modal.Title
            className="dk-candModalTitle"
            id="example-custom-modal-styling-title"
          >
              <div className="dkg-jobfaq-title">FAQ - JOB SEARCH</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="dk-livejobs-ModalBody">
          <FaqOverview />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default JobsFaqPopup;
