import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../jobsrightmodal.scss";
import { Modal, Tab, Nav } from "react-bootstrap";
import ClientinfoTab from "../clientinfo/ClientinfoTab";
import ClientProject from "../clientinfo/ClientProjectTab";
import ClientRoleTab from "../clientinfo/RoletypeTab";
// import PortalsTab from "../clientinfo/PortalTab";
// import JobSendoutTab from "../clientinfo/SendoutTab";
import WhyApply from "../clientinfo/LanguageTab";
//import SendoutTab from "../clientinfo/SendoutTab";
import InterviewsTab from "../clientinfo/InterviewsTab";
//import LanguageTab from "../clientinfo/LanguageTab";
import JobOfferTab from "../clientinfo/JobOfferTab";
import RelocationTab from "../clientinfo/RelocationTab";
import JoinningTab from "../clientinfo/JoinningTab";
import JobFaq from "../clientinfo/JobsFaqTab";
//import PortalTab from "../clientinfo/PortalTab";
import {
  allJobsSpecCompnay,
  defaultJobsCompnay,
  defaultJobsProject,
  defaultJobsRole,
  // defaultPortalProject,
  //defaultSendoutProject,
  defaultInterviewProject,
  defaultJobofferProject,
  defaultJoboRelocation,
  defaultJoinning,
  defaultWhyapply,
  defaultFaqs,
  allclientsproject,
  allclientsRoletype,
  // allclientsPortals,
  // allclientsSendout,
  allclientsInterview,
  allclientsJobOffer,
  allclientsRelocation,
  allclientsLanguge,
  allFaqs,
  allJoinning,
} from "../../../../../../slice/jobSpec/jobSpecSlice";

const JobSpecTab = ({ jobId, canjobs }) => {
  const dispatch = useDispatch();
  const [eventKey, SetEventKey] = useState("details");
  //const { companyDetails } = useSelector((state) => state.companies);

  useEffect(() => {
    if (eventKey) {
      switch (eventKey) {
        case "details":
          dispatch(
            defaultJobsCompnay({
              jobsId: canjobs,
              tbl: "project_relationship_companies",
            })
          );
          dispatch(allJobsSpecCompnay({}));
          break;
        case "title":
          dispatch(
            defaultJobsProject({
              jobsId: canjobs,
              tbl: "jobs_relationship_projects",
            })
          );
          dispatch(allclientsproject({}));
          break;
        case "rolestypes":
          dispatch(
            defaultJobsRole({
              jobsId: canjobs,
              tbl: "jobs_relationship_roletypes",
            })
          );
          dispatch(allclientsRoletype({}));
          break;
        // case "jobPortal":
        //   dispatch(
        //     defaultPortalProject({
        //       jobsId: canjobs,
        //       tbl: "jobs_relationship_portals",
        //     })
        //   );
        //   dispatch(allclientsPortals({}));
        //   break;
        // case "jobSendout":
        //   dispatch(
        //     defaultSendoutProject({
        //       jobsId: canjobs,
        //       tbl: "jobs_relationship_sendouts",
        //     })
        //   );
        //   dispatch(allclientsSendout({}));
        //   break;
        case "jobInterview":
          dispatch(
            defaultInterviewProject({
              jobsId: canjobs,
              tbl: "jobs_relationship_interviews",
            })
          );
          dispatch(allclientsInterview({}));
          break;
        case "jobOffer":
          dispatch(
            defaultJobofferProject({
              jobsId: canjobs,
              tbl: "jobs_relationship_joboffers",
            })
          );
          dispatch(allclientsJobOffer({}));
          break;
        case "jobRelocation":
          console.log("jobRelocation");
          //relocation-category
          dispatch(
            defaultJoboRelocation({
              jobsId: canjobs,
              tbl: "jobs_relationship_relocations",
            })
          );
          dispatch(allclientsRelocation({}));
          break;

        case "Joinning":
          dispatch(
            defaultJoinning({
              jobsId: canjobs,
              tbl: "jobs_client_europe_joinnings",
            })
          );
          dispatch(allJoinning({}));
          break;

        case "whyapplyy":
          dispatch(
            defaultWhyapply({
              jobsId: canjobs,
              tbl: "jobs_relationship_whyapplys",
            })
          );
          dispatch(allclientsLanguge({}));
          break;
        case "jobspec-faq":
          dispatch(
            defaultFaqs({
              jobsId: canjobs,
              tbl: "jobs_relationship_faqs",
            })
          );
          dispatch(allFaqs({}));
          break;
      }
    }
  }, [eventKey]);

  return (
    <>
      <Tab.Container id="left-tabs-example" defaultActiveKey="details">
        <div
          className="col-md-12 dkg-edit-jobdetail-tabpaneCon" style={{ height: "calc(100vh - 135px)" }}>
            <div className="row dkg-edit-livejobs-adsboxRow">
              <div className="col-md-12 dkg-edit-livejobs-adsboxCol">
                <div className="row dkg-edit-livejobs-adsboxRow-234">
                  <div className="col-md-2 col-sm-2 dkg-edit-livejobs-sidemenu pl-0 pr-0">
                    <Nav
                      variant="pills"
                      className="d-flex dkg-edit-adsbox-navpills"
                      onSelect={(selectedKey) => SetEventKey(selectedKey)}
                    >
                      <Nav.Item className="dkg-edit-adsbox-navitem">
                        <Nav.Link eventKey="details" className="dkg-edit-adsbox-nav-link">Company</Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="dkg-edit-adsbox-navitem">
                        <Nav.Link eventKey="title" className="dkg-edit-adsbox-nav-link">Project</Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="dkg-edit-adsbox-navitem">
                        <Nav.Link eventKey="rolestypes" className="dkg-edit-adsbox-nav-link">Role Type</Nav.Link>
                      </Nav.Item>
                      {/* <Nav.Item>
                        <Nav.Link eventKey="jobPortal">Portal</Nav.Link>
                      </Nav.Item> */}
                      {/* <Nav.Item>
                        <Nav.Link eventKey="jobSendout">Sendout</Nav.Link>
                      </Nav.Item> */}
                      <Nav.Item className="dkg-edit-adsbox-navitem">
                        <Nav.Link eventKey="jobInterview" className="dkg-edit-adsbox-nav-link">Interviews</Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="dkg-edit-adsbox-navitem">
                        <Nav.Link eventKey="jobOffer" className="dkg-edit-adsbox-nav-link">Job Offer</Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="dkg-edit-adsbox-navitem">
                        <Nav.Link eventKey="jobRelocation" className="dkg-edit-adsbox-nav-link">Relocation</Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="dkg-edit-adsbox-navitem">
                        <Nav.Link eventKey="Joinning" className="dkg-edit-adsbox-nav-link">Joining</Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="dkg-edit-adsbox-navitem">
                        <Nav.Link eventKey="whyapplyy" className="dkg-edit-adsbox-nav-link">Why Apply</Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="dkg-edit-adsbox-navitem">
                        <Nav.Link eventKey="jobspec-faq" className="dkg-edit-adsbox-nav-link">FAQ</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                  <div className="col-md-10 col-sm-10 dkg-editjob-adsboxRightSide pl-0 pr-0">
                    <Tab.Content  className="dkg-editjob-adsbox-jobcontent">
                      <Tab.Pane eventKey="details" className="dkg-editjob-adsbox-tabpane">
                        <div className="dk-ClentViewTabPane pl-3 pr-3 summaryTabPane">
                          <div className="row dk-Adsbox">
                            <div
                              className="col-md-12 interviewsTable p-0 mt-3"
                              style={{ height: "calc(100vh - 201px)" }}
                            >
                              <ClientinfoTab jobId={canjobs} />
                            </div>
                          </div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="title">
                        <div className="dk-ClentViewTabPane pl-3 pr-3 summaryTabPane">
                          <div className="row dk-Adsbox">
                            <div
                              className="col-md-12 interviewsTable mt-3 p-0"
                              style={{ height: "calc(100vh - 201px)" }}
                            >
                              <ClientProject jobId={canjobs} />
                            </div>
                          </div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="rolestypes">
                        <div className="dk-ClentViewTabPane pl-3 pr-3 summaryTabPane">
                          <div className="row dk-Adsbox">
                            <div
                              className="col-md-12 mt-3 p-0"
                              style={{ height: "calc(100vh - 201px)" }}
                            >
                              <ClientRoleTab jobId={canjobs} />
                            </div>
                          </div>
                        </div>
                      </Tab.Pane>
                      {/* <Tab.Pane eventKey="jobPortal">
                        <div className="dk-ClentViewTabPane pl-3 pr-3 summaryTabPane">
                          <div className="row dk-Adsbox">
                            <div
                              className="col-md-12 mt-3 p-0"
                              style={{ height: "calc(100vh - 201px)" }}
                            >
                              <PortalsTab jobId={canjobs} />
                            </div>
                          </div>
                        </div>
                      </Tab.Pane> */}
                      {/* <Tab.Pane eventKey="jobSendout">
                        <div className="dk-ClentViewTabPane pl-3 pr-3 summaryTabPane">
                          <div className="row dk-Adsbox">
                            <div
                              className="col-md-12 mt-3 p-0"
                              style={{ height: "calc(100vh - 201px)" }}
                            >
                              <JobSendoutTab jobId={canjobs} />
                            </div>
                          </div>
                        </div>
                      </Tab.Pane> */}
                      <Tab.Pane eventKey="jobInterview">
                        <div className="dk-ClentViewTabPane pl-3 pr-3 summaryTabPane">
                          <div className="row dk-Adsbox">
                            <div
                              className="col-md-12 mt-3 p-0"
                              style={{ height: "calc(100vh - 201px)" }}
                            >
                              <InterviewsTab jobId={canjobs} />
                            </div>
                          </div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="jobOffer">
                        <div className="dk-ClentViewTabPane">
                          <div className="dk-ClentViewTabPane pl-3 pr-3 summaryTabPane">
                            <div className="row dk-Adsbox">
                              <div
                                className="col-md-12 mt-3 p-0"
                                style={{ height: "calc(100vh - 201px)" }}
                              >
                                <JobOfferTab jobId={canjobs} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="jobRelocation">
                        <div className="dk-ClentViewTabPane">
                          <div className="dk-ClentViewTabPane pl-3 pr-3 summaryTabPane">
                            <div className="row dk-Adsbox">
                              <div
                                className="col-md-12 mt-3 p-0"
                                style={{ height: "calc(100vh - 201px)" }}
                              >
                                <RelocationTab jobId={canjobs} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="Joinning">
                        <div className="dk-ClentViewTabPane">
                          <div className="dk-ClentViewTabPane pl-3 pr-3 summaryTabPane">
                            <div className="row dk-Adsbox">
                              <div
                                className="col-md-12 mt-3 p-0"
                                style={{ height: "calc(100vh - 201px)" }}
                              >
                                <JoinningTab jobId={canjobs} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab.Pane>
                      {/* <Tab.Pane eventKey="adplan">
                                            <div className="dk-ClentViewTabPane summaryTabPane">
                                                <Adplan jobId={canjobs} />
                                            </div>
                                        </Tab.Pane> */}
                      <Tab.Pane eventKey="whyapplyy">
                        <div className="dk-ClentViewTabPane">
                          <div className="dk-ClentViewTabPane pl-3 pr-3 summaryTabPane">
                            <div className="row dk-Adsbox">
                              <div
                                className="col-md-12 interviewsTable mt-3 p-0"
                                style={{ height: "calc(100vh - 201px)" }}
                              >
                                <WhyApply jobId={canjobs} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="jobspec-faq">
                        <div className="dk-ClentViewTabPane">
                          <div className="dk-ClentViewTabPane pl-3 pr-3 summaryTabPane">
                            <div className="row dk-Adsbox">
                              <div
                                className="col-md-12 interviewsTable mt-3 p-0"
                                style={{ height: "calc(100vh - 201px)" }}
                              >
                                <JobFaq jobId={canjobs} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </Tab.Container>
        
    </>
  );
};

export default JobSpecTab;
