import React, { useEffect, useState, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import { clearState, updateJob } from "../../../../../../slice/jobs/jobsSlice";
import SubmitButton from "../../../../../ui/submitButton";

const EditorComp = ({ jobId, name, details }) => {
  const dispatch = useDispatch();
  const { isUpdate } = useSelector((state) => state.jobs);

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [editorDescription, setEditorDescription] = useState("");
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  let description =
    editorDescription !== undefined && editorDescription !== null
      ? editorDescription
      : "";
  const html = description;
  const contentBlock = htmlToDraft(html);
  const contentState = ContentState.createFromBlockArray(
    contentBlock.contentBlocks
  );

  useEffect(() => {
    setEditorDescription(details);
    setEditorState(EditorState.createEmpty());
    if (description !== "") {
      setEditorState(EditorState.createWithContent(contentState));
    }
  }, [description, details]);

  useEffect(() => {
    if (isUpdate) {
      dispatch(clearState());
      setSuccess(false);
      setLoading(false);
    }
  }, [isUpdate]);

  const editorStateChange = async (state) => {
    setEditorState(state);
    // let currentContentAsHTML = draftToHtml(
    //   convertToRaw(editorState.getCurrentContent())
    // );
    // dispatch(updateJob({ id: jobId, key: name, value: currentContentAsHTML }));
  };

  const handleButtonClick = async () => {
    let currentContentAsHTML = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    // console.log(jobId);

    setSuccess(true);
    setLoading(true);
    if (currentContentAsHTML == "<p></p>\n") {
      dispatch(updateJob({ id: jobId, key: name, value: "" }));
    } else {
      dispatch(
        updateJob({ id: jobId, key: name, value: currentContentAsHTML })
      );
    }
  };
  return (
    <>
      <Editor
        editorState={editorState}
        onEditorStateChange={editorStateChange}
        wrapperClassName="wrapper-class"
        editorClassName="editor-class"
        toolbarClassName="toolbar-class"
      />
      <div className="dkg-savebtnCon">
        <SubmitButton
          txt="SAVE"
          loading={loading}
          success={success}
          className="dkg-create-payment-taskbtn"
          onClick={handleButtonClick}
        />
      </div>
    </>
  );
};

export default EditorComp;
