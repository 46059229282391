import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "./appocation.scss";
import { Dropdown, Nav, Tab } from "react-bootstrap";
import { FaPlus, FaEdit, FaTimes, FaUser } from "react-icons/fa";
import ApplicationTable from "./ApplicationTable";
import {
  addApplication,
  getApplications,
} from "../../../../../../../slice/candidates/list/listSlice";
import { showSuccess } from "../../../../../../../slice/utils/message/messageSlice";
const Appication = ({ canId }) => {
  const dispatch = useDispatch();
  const { applicationList, isApplication, isAppUpdate, isAppDelete } =
    useSelector((state) => state.candidates);

  const [items, setItems] = useState([]);
  const [applicationData, setApplicationData] = useState([]);
  useEffect(() => {
    if (isApplication) {
      setApplicationData(applicationList);
      setApptitle(
        `JOBS APPLIED - ` +
          applicationList.filter((data) => data.applicationType === "Applied")
            .length
      );
    }
    if (isAppUpdate) {
      dispatch(showSuccess({ msg: "Record updated Successfully." }));
    }
    if (isAppDelete) {
      dispatch(showSuccess({ msg: "Record Deleted Successfully." }));
      dispatch(getApplications({ can_id: canId }));
    }
  }, [isApplication, isAppUpdate, isAppDelete]);

  // const addDiv = useCallback(() => {
  //   // using timestamp as a unique ID
  //   setItems([...items, new Date().getTime()]);
  // }, [items]);

  const [eventKey, SetEventKey] = useState("app-applied");
  const [applicationType, setApplicationType] = useState("app-applied");
  const [apptitle, setApptitle] = useState(
    `JOBS APPLIED - ` +
      applicationData.filter((data) => data.applicationType === "Applied")
        .length
  );

  useEffect(() => {
    if (eventKey) {
      switch (eventKey) {
        case "app-applied":
          setApplicationType("Applied");
          setApptitle(
            `JOBS APPLIED - ` +
              applicationData.filter(
                (data) => data.applicationType === "Applied"
              ).length
          );
          break;
        case "app-interviews":
          setApplicationType("Interviewed");
          setApptitle(
            `JOBS INTERVIEWED - ` +
              applicationData.filter(
                (data) => data.applicationType === "Interviewed"
              ).length
          );
          break;
        case "app-joboffers":
          setApplicationType("Offered");
          setApptitle(
            `JOBS OFFERED - ` +
              applicationData.filter(
                (data) => data.applicationType === "Offered"
              ).length
          );
          break;
        case "app-joined":
          setApplicationType("Joined");
          setApptitle(
            `JOBS JOINED - ` +
              applicationData.filter(
                (data) => data.applicationType === "Joined"
              ).length
          );
          break;
      }
    }
  }, [eventKey]);

  const addNewApplications = (type) => {
    dispatch(addApplication({ canId: canId, type }));
  };

  const removeDiv = useCallback(
    (itemId) => {
      // filter out the div which matches the ID
      setItems(items.filter((id) => id !== itemId));
    },
    [items]
  );
  return (
    <>
      <div className="dkg-appication-mainCon">
        <div className="dkg-appication-header">
          <div className="dkg-appication-leftCon">
            <Dropdown className="dkg-appication-dropdown">
              <Dropdown.Toggle variant="" className="dkg-app-dropdown-toggle">
                Year
              </Dropdown.Toggle>
              <Dropdown.Menu className="dkg-app-dropdown-menu">
                <Dropdown.Item href="#" className="dkg-app-dropdown-item">
                  2024
                </Dropdown.Item>
                <Dropdown.Item href="#" className="dkg-app-dropdown-item">
                  2023
                </Dropdown.Item>
                <Dropdown.Item href="#" className="dkg-app-dropdown-item">
                  2022
                </Dropdown.Item>
                <Dropdown.Item href="#" className="dkg-app-dropdown-item">
                  2021
                </Dropdown.Item>
                <Dropdown.Item href="#" className="dkg-app-dropdown-item">
                  2020
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="dkg-application-centreCon">
            <h2 className="dkg-application-title">{apptitle}</h2>
          </div>
          <div className="dkg-application-rightCon">
            <div className="dkg-addnew-appbtnCon">
              <Link
                to="#"
                className="dkg-addnew-appbtn btn"
                onClick={() => addNewApplications(applicationType)}
              >
                <FaPlus className="mr-2" /> Add New
              </Link>
            </div>
          </div>
        </div>
        <div className="dkg-appication-mainbox">
          <Tab.Container id="left-tabs-example" defaultActiveKey="app-applied">
            <div className="row dkg-appication-mainRow">
              <div className="col-2 col-md-2 dkg-appication-leftCol">
                <Nav
                  variant="pills dkg-appication-navpills"
                  className="flex-column"
                  onSelect={(selectedKey) => SetEventKey(selectedKey)}
                >
                  <Nav.Item className="dkg-appication-navitem">
                    <Nav.Link
                      eventKey="app-applied"
                      className="dkg-appication-navlink"
                    >
                      Jobs Applied
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-appication-navitem">
                    <Nav.Link
                      eventKey="app-interviews"
                      className="dkg-appication-navlink"
                    >
                     Interviewed
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-appication-navitem">
                    <Nav.Link
                      eventKey="app-joboffers"
                      className="dkg-appication-navlink"
                    >
                      Jobs Offered
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-appication-navitem">
                    <Nav.Link
                      eventKey="app-joined"
                      className="dkg-appication-navlink"
                    >
                      Jobs Joined
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>

              <div
                className="col-10 col-md-10 dkg-appication-rightCol pr-4"
                id={applicationData.length}
              >
                <Tab.Content className="dkg-appication-tabContent">
                  <Tab.Pane
                    eventKey="app-applied"
                    className="dkg-appication-tabpane"
                  >
                    <div className="dkg-app-applied-tableCon">
                      {applicationData
                        .filter((data) => data.applicationType === "Applied")
                        .map((item, index) => (
                          <table
                            className="table dkg-app-applied-table mt-0 mb-0"
                            key={index + 1}
                          >
                            <thead>
                              <tr>
                                <th>Job Applied</th>
                                <th>Job Status</th>
                                <th>Job Stage</th>
                                <th>Job Progress</th>
                                <th>Last Updated</th>
                                <th></th>
                              </tr>
                            </thead>

                            <ApplicationTable
                              key={index + 1}
                              id={index + 2}
                              removeDiv={removeDiv}
                              itemdata={item}
                            />
                          </table>
                        ))}
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="app-interviews"
                    className="dkg-appication-tabpane"
                  >
                    {/* {applicationData
                      .filter((data) => data.applicationType === "Interviewed")
                      .map((item, index) => (
                        <div className="dkg-app-applied-tableCon">
                          <table
                            className="table dkg-app-applied-table mt-0 mb-0"
                            key={index + 1}
                          >
                            <thead>
                              <tr>
                                <th>Job Applied</th>
                                <th>Job Status</th>
                                <th>Job Stage</th>
                                <th>Job Progress</th>
                                <th>Last Updated</th>
                                <th></th>
                              </tr>
                            </thead>

                            <ApplicationTable
                              key={index + 1}
                              id={index + 2}
                              removeDiv={removeDiv}
                              itemdata={item}
                            />
                          </table>
                        </div>
                      ))} */}
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="app-joboffers"
                    className="dkg-appication-tabpane"
                  >
                    {/* {applicationData
                      .filter((data) => data.applicationType === "Offered")
                      .map((item, index) => (
                        <div className="dkg-app-applied-tableCon">
                          <table
                            className="table dkg-app-applied-table mt-0 mb-0"
                            key={index + 1}
                          >
                            <thead>
                              <tr>
                                <th>Job Applied</th>
                                <th>Job Status</th>
                                <th>Job Stage</th>
                                <th>Job Progress</th>
                                <th>Last Updated</th>
                                <th></th>
                              </tr>
                            </thead>

                            <ApplicationTable
                              key={index + 1}
                              id={index + 2}
                              removeDiv={removeDiv}
                              itemdata={item}
                            />
                          </table>
                        </div>
                      ))} */}
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="app-joined"
                    className="dkg-appication-tabpane"
                  >
                    {/* {applicationData
                      .filter((data) => data.applicationType === "Joined")
                      .map((item, index) => (
                        <div className="dkg-app-applied-tableCon">
                          <table
                            className="table dkg-app-applied-table mt-0 mb-0"
                            key={index + 1}
                          >
                            <thead>
                              <tr>
                                <th>Job Applied</th>
                                <th>Job Status</th>
                                <th>Job Stage</th>
                                <th>Job Progress</th>
                                <th>Last Updated</th>
                                <th></th>
                              </tr>
                            </thead>

                            <ApplicationTable
                              key={index + 1}
                              id={index + 2}
                              removeDiv={removeDiv}
                              itemdata={item}
                            />
                          </table>
                        </div>
                      ))} */}
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </div>
          </Tab.Container>
        </div>
      </div>
    </>
  );
};

export default Appication;
