import React, { useState } from 'react'; 
import { Link } from 'react-router-dom';
import  './emaillogs.scss'
import { FaEdit ,FaTrashAlt } from "react-icons/fa"; 
import IncomingEmailIcon from '../../../../../../../assets/images/incoming-mail.png'
import OutgoingEmailIcon from '../../../../../../../assets/images/mail-out.png'
import UserTo from "../../../../../../../assets/images/user-defult.jpg";
import { useSelector, useDispatch } from "react-redux";
import CustomDateFormat from "../../../../../../ui/dateFormats/CustomDateFormat";
import EmailContent from './modal/EmailContent';
import { DeleteData } from "../../../../../../../services";
import { getEmailLogs } from "../../../../../../../slice/candidates/list/listSlice";
import { showSuccess, showError, clearMessage } from "../../../../../../../slice/utils/message/messageSlice";
import { Modal } from 'react-bootstrap'

const EmailLogs = (props) => {
  const dispatch = useDispatch();
  const [isDeleteConfirmPopUp, setIsDeleteConfirmPopUp] = useState(false);
  const [deleteAttachmentId, setDeleteAttachmentId] = useState(0)

  const deleteAttachment = async () => {
    try {
      if (deleteAttachmentId == 0) return;
      const deleteAttachment = await DeleteData(true, '/candidates/profile/email-logs/' + deleteAttachmentId)
      if (deleteAttachment.payload.status) {
        dispatch(getEmailLogs({ can_id: props.candidateId }));
        setDeleteAttachmentId(0)
        setIsDeleteConfirmPopUp(false)
        dispatch(showSuccess({ msg: 'Log deleted successfully' }))
      }
    } catch (error) {
      dispatch(showError({ msg: error.message }))
    }
  }

  const confirmDelete = (id) => {
    setDeleteAttachmentId(id)
    setIsDeleteConfirmPopUp(true)
  }
  
  const { emailLogs, isLoadingEmailLogs } = useSelector(
    (state) => state.candidates
  );
  return (
    <>
      <div className='dkg-emaillogs-mainCon'>
        <div className='dkg-emaillogs-Header'>
            <div className='dkg-emaillogs-LeftCon'></div>
            <div className='dkg-emaillogs-centreCon'></div>
            <div className='dkg-emaillogs-rightCon'></div>
        </div>
        <Modal className="dk-addNewCompletionModal dk-deleteModalMain" centered show={isDeleteConfirmPopUp} onHide={() => {
          setIsDeleteConfirmPopUp(false)
          setDeleteAttachmentId(0)
        }}>
            <Modal.Body>
              <h4>Are you sure wants to delete these records !</h4>
              <div className="d-flex justify-content-end">
                <button className="btnRed" onClick={() => deleteAttachment()}>Yes</button>
                <button onClick={() => {
                  setDeleteAttachmentId(0)
                  setIsDeleteConfirmPopUp(false)
                }}>No</button>
              </div>
            </Modal.Body>
          </Modal>
        <div className='dkg-emaillogs-tableCon table-responsive'>
            <table className='table table-bordered dkg-emaillogs-table'>
                <thead>
                   <tr>
                      <th>Flow</th>
                      <th>Date & Time</th>
                      <th>Status</th>
                      <th>Category</th>
                      <th>Email Subject </th>
                      <th>Sender</th>
                      <th>Receiver</th>
                      <th>Actions</th>
                   </tr>
                </thead>
                <tbody>
                  {
                    emailLogs.length > 0 ? 
                      emailLogs.map((log) => (
                        <tr>
                          <td>
                            <div className='dk-out-incom-emailicon'>
                              <div className='dk-out-emailicon'>
                                <img src={log.is_incoming ? IncomingEmailIcon : OutgoingEmailIcon} alt='Email Incoming' className='dk-out-email-img' />
                              </div>
                            </div>
                          </td>
                          <td>
                            <CustomDateFormat
                              date={log.createdAt}
                              format="DD MMM YY , h:mm A"
                            />
                          </td>
                          <td>
                            <div className='dkg-email-log-status' style={{backgroundColor: !log.is_incoming ? "#d58b5d" : "#316a67" , color: "#fff"}}>{ log.status }</div>
                          </td>
                          <td>Welcome</td>
                          <td>
                          <EmailContent details={log}/>
                          </td>
                          <td>
                            <div className='dkg-userimg-Con'>
                            {/* process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                                item.profileImage */}
                              <img src={
                                log.is_incoming ? UserTo : process.env.REACT_APP_IMG_CLOUD_BASE_URL + log.mailgun_user.profileImage
                              } alt='User From' className='dkg-userimg' />
                              <span className='dkg-user-email'>{ log.sender }</span>
                            </div>
                          </td>
                          <td>
                          <div className='dkg-userimg-Con'>
                              <img src={log.is_incoming ? process.env.REACT_APP_IMG_CLOUD_BASE_URL + log.mailgun_user.profileImage : UserTo } alt='User To' className='dkg-userimg' />
                              <span className='dkg-user-email'>{ log.recipient }</span>
                            </div>
                          </td>
                          <td>
                            <div className='dkg-email-actions-cols'>
                              <Link to="#" className="dkg-email-action-icon view-icon mr-2">
                                <FaEdit />
                              </Link>
                              <div className="dkg-email-action-icon delete-icon" onClick={() => confirmDelete(log.id)}>
                                <FaTrashAlt />
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))
                    :
                    <tr align="center">
                      <td colspan="8">No data found</td>
                    </tr>
                  }
                </tbody>
            </table>
        </div>
      </div>
    
    </>
  )
}

export default EmailLogs