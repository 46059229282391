import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  defaultJobsRole,
  updateRoleValue,
} from "../../../../../../slice/jobSpec/jobSpecSlice";

const RoletypeTab = ({ jobId }) => {
  const dispatch = useDispatch();
  const { isLoading, roletypeRelationData, roletypeList } = useSelector(
    (state) => state.jobsSpec
  );
  const { companyDetails } = useSelector((state) => state.companies);

  //const [data, setData] = useState([]);

  //useEffect(() => {
  // if (!isLoading && roletypeRelationData && roletypeRelationData.length > 0) {
  //   setData(roletypeRelationData);
  // }
  //}, [isLoading]);

  const [selectedItem, setSelectedItem] = useState(1);

  const handleCheckboxChange = async (e) => {
    let v;
    if (e.target.checked === true) {
      v = 1;
    } else {
      v = 0;
    }
    await dispatch(
      updateRoleValue({
        id: e.target.dataset.id,
        key: "isShowInJob",
        jobsId: jobId,
        value: v,
        clientId: companyDetails.clientId,
        clientname: e.target.dataset.companyname,
        tableName: "jobs_relationship_roletypes",
      })
    );
    await dispatch(
      defaultJobsRole({
        jobsId: jobId,
        tbl: "jobs_relationship_roletypes",
      })
    );
  };

  const updatedData = roletypeRelationData.map((item) => {
    const matchingCompany = roletypeList.find(
      (company) =>
        company.companyName === item.categoryName &&
        company.isShowInJob === 1 &&
        company.jobsId === jobId
    );
    if (matchingCompany) {
      return { ...item, isShowInJob: 1 };
    } else {
      return { ...item, isShowInJob: 0 };
    }
  });

  return (
    <>
      <div className="dkg-company-list-mainCon">
        <div className="dkg-company-list-tableCon table-responsive">
          <table className="dkg-company-list-table table table-borderded">
            <thead>
              <tr>
                <th>Role Type</th>
                <th>Linked</th>
              </tr>
            </thead>
            <tbody>
              {updatedData.map((details, index) => (
                <tr key={index + 1}>
                  <td>{details.categoryName}</td>
                  <td>
                    <span className="dkg-clientinfo-checkbox">
                      <input
                        type="checkbox"
                        className="form-control"
                        name="clientinfo-checkbox"
                        checked={details.isShowInJob === selectedItem}
                        data-id={details.id}
                        data-companyname={details.categoryName}
                        onChange={handleCheckboxChange}
                        id={details.id}
                      />
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default RoletypeTab;
