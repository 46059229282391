import React from 'react';
// import "./casedrpdwn.scss";
import Dropdown from 'react-bootstrap/Dropdown'

const StatusDopdown = ({ caseStatus, candidateId, list, updateProfile, errorClass }) => {
    let bgcolur ;
    let txtColor ;
    
if(caseStatus=="Open"){
    bgcolur="#03a786"
    txtColor = '#fff'
}else if(caseStatus=="Closed"){
    bgcolur="#8b572a"
    txtColor = '#fff'
}else{
    bgcolur="#03a786"
    txtColor = '#fff'
}
    return (
        <>
            
            <div className="dk-app-caseDrpDwn w-100">
                <div className={errorClass}>
                    <div variant="" className="caseStstoggle dk-app-status-toggle" style={{ backgroundColor: bgcolur, borderColor: bgcolur,color: txtColor }}>
                        {caseStatus}
                    </div>
                    {/* <Dropdown.Menu className="dkg-caseStatus-appDropMenu">
                        {
                            list.map((statusItem, index) =>
                                <Dropdown.Item key={`profile-case-status-id${index}`} href="#" style={{ backgroundColor: statusItem.bgColor, color: statusItem.textColor }} data-id={candidateId} data-value={statusItem.value} data-key="newprofilestatus" >{statusItem.value}</Dropdown.Item>
                            )
                        }
                        <Dropdown.Item  href="#" className='dk-app-status-item' style={{ backgroundColor: "#03a786", color: "#fff" }}>Open</Dropdown.Item>
                        <Dropdown.Item  href="#" className='dk-app-status-item' style={{ backgroundColor: "#8b572a", color: "#fff" }}>Closed</Dropdown.Item>
                    </Dropdown.Menu> */}
                </div>
            </div>
        </>
    )
}

export default StatusDopdown;
