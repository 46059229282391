import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { useSelector } from "react-redux";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function CustomizedAccordions({ quesAnswer }) {
  const { isSuccess } = useSelector((state) => state.jobsfaqData);
  const [data, setData] = useState([]);
  useEffect(() => {
    setData(quesAnswer);
  }, [isSuccess]);

  const [expanded, setExpanded] = React.useState("panel0");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const textRef = React.useRef();
  const [value, setValue] = React.useState();
  // const onChnage = (event) => {
  //   setValue(event.target.value);
  // };
  React.useEffect(() => {
    if (textRef && textRef.current) {
      textRef.current.style.height = "0px";
      const taHeight = textRef.current.scrollHeight;
      textRef.current.style.height = taHeight + "px";
    }
  }, [value]);
  return (
    <>
      {data.length > 0 ? (
        <div>
          {data
            .filter((item) => item.status === "Active")
            .map((queAns, index) => {
              return (
                <Accordion
                  expanded={expanded == `panel` + index}
                  onChange={handleChange(`panel` + index)}
                  className="dkg-screen-faq-accodionCon"
                >
                  <AccordionSummary
                    aria-controls={`panel` + index + `d-content`}
                    id={`panel` + index + `d-header`}
                    className="dkg-screen-faq-accodion"
                  >
                    <Typography className="dkg-screen-faq-accodion-suMM">
                      {queAns.question}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    style={{ backgroundColor: "#24475f", color: "#fff" }}
                  >
                    <Typography>
                      <TextareaAutosize
                        aria-label="minimum height"
                        // rowsMin={3}
                        className="w-100 dkg-faq-answertextArea"
                        value={queAns.answer}
                        style={{
                          backgroundColor: "#24475f",
                          color: "#fff",
                          border: "none",
                        }}
                      />
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              );
            })}
        </div>
      ) : null}
    </>
  );
}
