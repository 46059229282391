import React, { useState } from "react";
//import FormControl from 'react-bootstrap/FormControl';
import Dropdown from "react-bootstrap/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import DefaultProfile from "../../../../../../assets/images/default_profile_empty.png";
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href="/#"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </a>
));

const CustomMenu = React.forwardRef(
  ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
    const [value, setValue] = useState("");
    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        {/* <FormControl
                    autoFocus
                    className=""
                    placeholder="Search Text..."
                    onChange={(e) => setValue(e.target.value)}
                    value={value}
                /> */}
        <ul className="list-unstyled">
          {React.Children.toArray(children).filter(
            (child) =>
              !value ||
              child.props.children.toString().toLowerCase().startsWith(value)
          )}
        </ul>
      </div>
    );
  }
);

const RecruiterAdminDropDwn = (props) => {
  //const dispatch = useDispatch();
  const { usersList } = useSelector((state) => state.users);

  const removeValFromIndex = [9, 10, 11]; //not to see users in pipeline, remove user from usersList
  const indexSet = new Set(removeValFromIndex);
  const newUsers = usersList.filter((value, i) => !indexSet.has(i));

  const selectValue = (value, color) => {
    console.log(value);
    console.log(color);
    console.log(props.canId);

    //props.updateProfile(id, columnId, value, color);
  };

  return (
    <>
      <div className="dk-selectRecDropDwn">
        <Dropdown className="dkg-can-ra-dropdown-567">
          <Dropdown.Toggle id="dropdown-custom-components" as={CustomToggle}>
            <div className="tooltipMain">
              <img
                alt={props.name}
                src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + props.img}
                className="dkreCImg"
                title="RA"
              />
              <div className="tooltipText">{props.name}</div>
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu as={CustomMenu} className="dk-candDropDwnMenu">
            {newUsers.map((list, index) => (
              <Dropdown.Item
                key={`recDropdown${index}`}
                data-id={props.canId}
                data-value={list.id}
                data-name={list.firstName + " " + list.lastName}
                data-img={list.profileImage}
                data-key="rec_admin"
                onClick={props.updateProfile}
              >
                <span
                  className="ImgCricleCon"
                  data-id={props.canId}
                  data-value={list.id}
                  data-name={list.firstName + " " + list.lastName}
                  data-img={list.profileImage}
                  data-key="rec_admin"
                >
                  <img
                    src={
                      process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                      list.profileImage
                    }
                    className="dkreCItemImg"
                    alt={list.firstName + " " + list.lastName}
                    data-id={props.canId}
                    data-value={list.id}
                    data-name={list.firstName + " " + list.lastName}
                    data-img={list.profileImage}
                    data-key="rec_admin"
                  />
                </span>
                <span
                  className="reCName"
                  data-id={props.canId}
                  data-value={list.id}
                  data-name={list.firstName + " " + list.lastName}
                  data-img={list.profileImage}
                  data-key="rec_admin"
                >
                  {list.firstName + " " + list.lastName}
                </span>
              </Dropdown.Item>
            ))}
            <Dropdown.Item
              data-id="-1"
              onClick={props.updateProfile}
              data-key="rec_admin"
            >
              <div className="tooltipMain">
                <img src={DefaultProfile} className="filterImg" alt="No User" />
                <div className="tooltipText">No User</div>
              </div>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </>
  );
};

export default RecruiterAdminDropDwn;
