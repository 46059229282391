import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GetData, PostData } from "../../services";
import { SYSTEM_CONSTANTS } from "../../constants";

// export const jobOffersReasionCase = createAsyncThunk(
//     'status/jobofferlist/reasion',
//     async (thunkAPI) => {
//         const res = await GetData(true, '/admin/backend-value/' + SYSTEM_CONSTANTS.BUSINESS_BOOK_INTERVIEWS_STATUS.jobofferResionId)
//         if (res.status === 200) {
//             return res.payload
//         } else {
//             return thunkAPI.rejectWithValue();
//         }
//     }
// )
export const qualifiedReasionCase = createAsyncThunk(
  "status/qualifiedlist/reasion",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" +
        SYSTEM_CONSTANTS.BUSINESS_BOOK_INTERVIEWS_STATUS.qualifiedResionId
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const qualifiedCaseStatus = createAsyncThunk(
  "interviewstatus/qualifiedStagelist/case-status",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" +
        SYSTEM_CONSTANTS.BUSINESS_BOOK_INTERVIEWS_STATUS.qualifiedCaseStatusId
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const qualifiedCaseProgress = createAsyncThunk(
  "interviewstatus/qualifiedProgresslist/case-status",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" +
        SYSTEM_CONSTANTS.BUSINESS_BOOK_INTERVIEWS_STATUS.qualifiedCaseProgId
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const qualifiedCaseStage = createAsyncThunk(
  "interviewstatus/qualifiedStatelist/case-status",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" +
        SYSTEM_CONSTANTS.BUSINESS_BOOK_INTERVIEWS_STATUS.qualifiedCaseStageId
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const joinedCaseStatus = createAsyncThunk(
  "interviewstatus/joinedStagelist/case-status",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" +
        SYSTEM_CONSTANTS.BUSINESS_BOOK_INTERVIEWS_STATUS.JoinedCaseStatusID
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const joinedCaseProgress = createAsyncThunk(
  "interviewstatus/joinedProgresslist/case-status",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" +
        SYSTEM_CONSTANTS.BUSINESS_BOOK_INTERVIEWS_STATUS.JoinedCaseProgressID
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const joinedCaseStage = createAsyncThunk(
  "interviewstatus/joinedStatelist/case-status",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" +
        SYSTEM_CONSTANTS.BUSINESS_BOOK_INTERVIEWS_STATUS.JoinedCaseStageID
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const finalFeeCaseStatus = createAsyncThunk(
  "interviewstatus/finalfeeStatus/case-status",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" +
        SYSTEM_CONSTANTS.BUSINESS_BOOK_INTERVIEWS_STATUS.finalFeeCaseStatusId
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const finalFeeCaseProgress = createAsyncThunk(
  "interviewstatus/finalfee/progress",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" +
        SYSTEM_CONSTANTS.BUSINESS_BOOK_INTERVIEWS_STATUS.finalFeeCaseProgId
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const finalFeeCaseStage = createAsyncThunk(
  "interviewstatus/finalfee/stage",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" +
        SYSTEM_CONSTANTS.BUSINESS_BOOK_INTERVIEWS_STATUS.finalFeeCaseStageId
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const jobOffersCaseStage = createAsyncThunk(
  "interviewstatus/jobofferStagelist/case-status",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" +
        SYSTEM_CONSTANTS.BUSINESS_BOOK_INTERVIEWS_STATUS.JobOfferCaseStageID
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const jobOffersCaseProgress = createAsyncThunk(
  "interviewstatus/jobofferProgreslist/case-status",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" +
        SYSTEM_CONSTANTS.BUSINESS_BOOK_INTERVIEWS_STATUS.JobOfferCaseProgressID
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const jobOffersCaseStatus = createAsyncThunk(
  "interviewstatus/jobofferlist/case-status",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" +
        SYSTEM_CONSTANTS.BUSINESS_BOOK_INTERVIEWS_STATUS.JobOfferCaseStatusID
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const interviewCaseStatus = createAsyncThunk(
  "interviewstatus/list/case-status",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" +
        SYSTEM_CONSTANTS.BUSINESS_BOOK_INTERVIEWS_STATUS.caseStatusID
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const reasionCase = createAsyncThunk(
  "status/interviewlist/reasion",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" +
        SYSTEM_CONSTANTS.BUSINESS_BOOK_INTERVIEWS_STATUS.resionId
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const interviewCaseStage = createAsyncThunk(
  "status/interviewlist/case-stage",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" +
        SYSTEM_CONSTANTS.BUSINESS_BOOK_INTERVIEWS_STATUS.CaseStageID
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const interviewCaseProgress = createAsyncThunk(
  "status/interview/list/case-progress",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" +
        SYSTEM_CONSTANTS.BUSINESS_BOOK_INTERVIEWS_STATUS.caseProgressID
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const profilesStatus = createAsyncThunk(
  "status/list/profile-status",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" + SYSTEM_CONSTANTS.CANDIDATES.ProfilesStatusID
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const caseStatus = createAsyncThunk(
  "status/list/case-status",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" + SYSTEM_CONSTANTS.CANDIDATES.CaseStatusID
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const pipelineClearSummaryRcFilter = createAsyncThunk(
  "pipeline/clearFilter",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/candidates/list/pipeline/filter/summary/clearFilter"
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const pipelineSummaryRcFilter = createAsyncThunk(
  "pipeline/list/summaryfilterRc",
  async ({ rec_id, RecValue, RecName }, thunkAPI) => {
    const res = await PostData(
      true,
      "/candidates/list/pipeline/filter/summary/recruter/",
      { rec_id, RecValue, RecName }
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const getFileTypeList = createAsyncThunk(
  "candidate/docTypes",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" + SYSTEM_CONSTANTS.CANDIDATE_DOCS_TYPE.ID
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const caseProgress = createAsyncThunk(
  "status/list/case-progress",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" + SYSTEM_CONSTANTS.CANDIDATES.CaseProgressID
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const caseStage = createAsyncThunk(
  "status/list/case-stage",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" + SYSTEM_CONSTANTS.CANDIDATES.CaseStageID
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const candidateCategory = createAsyncThunk(
  "candidate/profile/category",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" + SYSTEM_CONSTANTS.CANDIDATES.CategoryID
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const candidateNationality = createAsyncThunk(
  "candidate/profile/nationality",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" + SYSTEM_CONSTANTS.CANDIDATES.NationalityID
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const candidateVisaStatus = createAsyncThunk(
  "candidate/profile/visa",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" + SYSTEM_CONSTANTS.CANDIDATES.VisaStatusID
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const candidateNative = createAsyncThunk(
  "candidate/profile/native",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" + SYSTEM_CONSTANTS.CANDIDATES.NativeID
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const candidateFluent = createAsyncThunk(
  "candidate/profile/fluent",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" + SYSTEM_CONSTANTS.CANDIDATES.FluentID
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const candidateIntermediate = createAsyncThunk(
  "candidate/profile/intermediate",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" + SYSTEM_CONSTANTS.CANDIDATES.IntermediatID
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const candidateGender = createAsyncThunk(
  "candidate/profile/gender",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" + SYSTEM_CONSTANTS.CANDIDATES.GenderID
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const candidateCVSource = createAsyncThunk(
  "candidate/profile/CVSource",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" + SYSTEM_CONSTANTS.CANDIDATES.CVSourceID
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const candidateIndustry = createAsyncThunk(
  "candidate/profile/industry",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" + SYSTEM_CONSTANTS.CANDIDATES.IndustryID
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const candidateCareerLevel = createAsyncThunk(
  "candidate/profile/career-level",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" + SYSTEM_CONSTANTS.CANDIDATES.careerLevelID
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const candidateCityLevel = createAsyncThunk(
  "candidate/profile/city",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" + SYSTEM_CONSTANTS.CANDIDATES.CityID
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const candidateCountryLevel = createAsyncThunk(
  "candidate/profile/country",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" + SYSTEM_CONSTANTS.CANDIDATES.CountryID
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const candidateLatestJob = createAsyncThunk(
  "candidate/profile/latest-job",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" + SYSTEM_CONSTANTS.CANDIDATES.LatestJob
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const candidateEduation = createAsyncThunk(
  "candidate/profile/education",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" + SYSTEM_CONSTANTS.CANDIDATES.Education
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const candidateCareerPriority = createAsyncThunk(
  "candidate/profile/latest-priority",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" + SYSTEM_CONSTANTS.CANDIDATES.CasePriorit
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
/********Resources start */
export const resourceCategory = createAsyncThunk(
  "resource/mlResource/category",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" + SYSTEM_CONSTANTS.RESOURCE.CategoryId
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const resourceCoverage = createAsyncThunk(
  "resource/mlResource/coverage",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" + SYSTEM_CONSTANTS.RESOURCE.CoverageId
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const resourcePaidUnpaid = createAsyncThunk(
  "resource/mlResource/paidUnpaid",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" + SYSTEM_CONSTANTS.RESOURCE.PaidUnpaidId
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const resourceSourceType = createAsyncThunk(
  "resource/mlResource/sourceType",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" + SYSTEM_CONSTANTS.RESOURCE.SourceTypeId
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const resourceStatus = createAsyncThunk(
  "resource/mlResource/status",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" + SYSTEM_CONSTANTS.RESOURCE.StatusId
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const resourceLoginCategory = createAsyncThunk(
  "resource/logins/category",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" + SYSTEM_CONSTANTS.RESOURCE.LogingsCategoryId
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const resourceLoginSourceType = createAsyncThunk(
  "resource/logins/sourceType",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" + SYSTEM_CONSTANTS.RESOURCE.LogingsSourceTypeId
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
/********Resources end */
/*************ADS**************** */
export const adsJobboard = createAsyncThunk(
  "ads/jobboard/list",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" + SYSTEM_CONSTANTS.ADS.JobBoardId
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const adsCompany = createAsyncThunk(
  "ads/company/list",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" + SYSTEM_CONSTANTS.ADS.CompanyId
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const adsLanguage = createAsyncThunk(
  "ads/language/list",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" + SYSTEM_CONSTANTS.ADS.LanguageId
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const adsRoleType = createAsyncThunk(
  "ads/RolesType/list",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" + SYSTEM_CONSTANTS.ADS.RoleTypeId
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
/*************ADS End**************** */

/*************JOBS START**************** */

export const jobsCategory = createAsyncThunk(
  "jobs/Category/list",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" + SYSTEM_CONSTANTS.JOBS.CategoryID
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const jobsCompany = createAsyncThunk(
  "jobs/Company/list",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" + SYSTEM_CONSTANTS.JOBS.CompanyId
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const jobsCount = createAsyncThunk(
  "jobs/Count/list",
  async (thunkAPI) => {
    const res = await GetData(true, "/jobs/list/livejobs/number-count");
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const applicationList = createAsyncThunk(
  "jobspec/application/list",
  async ({ status }, thunkAPI) => {
    const res = await PostData(
      true,
      "/jobSpec/list/livejobs/application-list",
      {
        status,
      }
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const jobsProject = createAsyncThunk(
  "jobs/Project/list",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" + SYSTEM_CONSTANTS.JOBS.ProjectId
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const jobsRoleType = createAsyncThunk(
  "jobs/RoleType/list",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" + SYSTEM_CONSTANTS.JOBS.RoleTypeId
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const jobsLocality = createAsyncThunk(
  "jobs/Locality/list",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" + SYSTEM_CONSTANTS.JOBS.LocalityId
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const jobsNoOfJobs = createAsyncThunk(
  "jobs/NoofJobs/list",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" + SYSTEM_CONSTANTS.JOBS.NoOfJobsId
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const jobsCountry = createAsyncThunk(
  "jobs/Country/list",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" + SYSTEM_CONSTANTS.JOBS.CountryId
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const jobsCity = createAsyncThunk("jobs/City/list", async (thunkAPI) => {
  const res = await GetData(
    true,
    "/admin/backend-value/" + SYSTEM_CONSTANTS.JOBS.CityId
  );
  if (res.status === 200) {
    return res.payload;
  } else {
    return thunkAPI.rejectWithValue();
  }
});

export const jobsLanguages = createAsyncThunk(
  "jobs/Languages/list",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" + SYSTEM_CONSTANTS.JOBS.LanguagesId
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const countryCurrency = createAsyncThunk(
  "jobs/Currency/list",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" + SYSTEM_CONSTANTS.JOBS.currencyId
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const jobsJobProfile = createAsyncThunk(
  "jobs/JobProfile/list",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" + SYSTEM_CONSTANTS.JOBS.JobProfileId
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const jobsWorkPermit = createAsyncThunk(
  "jobs/WorkPermit/list",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" + SYSTEM_CONSTANTS.JOBS.WorkPermitId
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const jobsWorkplace = createAsyncThunk(
  "jobs/Workplace/list",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" + SYSTEM_CONSTANTS.JOBS.WorkPlaceId
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const jobsWorkdays = createAsyncThunk(
  "jobs/WorkDays/list",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" + SYSTEM_CONSTANTS.JOBS.WorkDaysId
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const jobsShiftsWork = createAsyncThunk(
  "jobs/ShiftsWork/list",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" + SYSTEM_CONSTANTS.JOBS.ShiftsWorkId
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const jobsContracttype = createAsyncThunk(
  "jobs/ContractType/list",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" + SYSTEM_CONSTANTS.JOBS.ContractTypeId
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const jobsSalarytype = createAsyncThunk(
  "jobs/SalaryType/list",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" + SYSTEM_CONSTANTS.JOBS.SalaryTypeId
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const jobsAvailability = createAsyncThunk(
  "jobs/Availability/list",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" + SYSTEM_CONSTANTS.JOBS.AvailabilityId
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const jobsOutsideofeu = createAsyncThunk(
  "jobs/OutsideofEU/list",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" + SYSTEM_CONSTANTS.JOBS.OutsideofEUId
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const jobsFlightticket = createAsyncThunk(
  "jobs/FlightTicket/list",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" + SYSTEM_CONSTANTS.JOBS.FlightTicketId
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const jobsTaxifromairport = createAsyncThunk(
  "jobs/TaxifromAirport/list",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" + SYSTEM_CONSTANTS.JOBS.TaxifromAirportId
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const jobsAccommodationt = createAsyncThunk(
  "jobs/Accommodationt/list",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" + SYSTEM_CONSTANTS.JOBS.AccommodationtId
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const jobWorktype = createAsyncThunk(
  "jobs/JobWorkType/list",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" + SYSTEM_CONSTANTS.JOBS.workTypeId
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const jobRelocation = createAsyncThunk(
  "jobs/Relocations/list",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" + SYSTEM_CONSTANTS.JOBS.RelocationId
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const jobsRelocationbudget = createAsyncThunk(
  "jobs/RelocationBudget/list",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" + SYSTEM_CONSTANTS.JOBS.RelocationBudgetId
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const jobsskills = createAsyncThunk(
  "jobs/jobskilled/list",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" + SYSTEM_CONSTANTS.JOBS.jobsSkillsId
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const jobitskills = createAsyncThunk(
  "jobs/itskills/list",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" + SYSTEM_CONSTANTS.JOBS.itSkillsId
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const jobsStatusId = createAsyncThunk(
  "jobs/jobstatus/list",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" + SYSTEM_CONSTANTS.JOBS.jobStatusId
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const jobssendoutStepId = createAsyncThunk(
  "jobs/jobsendouts/list",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" + SYSTEM_CONSTANTS.JOBS.sendoutStepId
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const jobsSendoutsMethod = createAsyncThunk(
  "jobs/sendoutsMethodd/list",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" + SYSTEM_CONSTANTS.JOBS.sendoutMethodId
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const jobsInterviewsMethod = createAsyncThunk(
  "jobs/jobinterviewMethod/list",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" + SYSTEM_CONSTANTS.JOBS.jobInterviewMethodId
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const jobssInterviews = createAsyncThunk(
  "jobs/jobinterview/list",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" + SYSTEM_CONSTANTS.JOBS.jobInterrviewId
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const jobsExperiencet = createAsyncThunk(
  "jobs/Experiencet/list",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" + SYSTEM_CONSTANTS.JOBS.ExperiencetId
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const jobsIndustryId = createAsyncThunk(
  "jobs/IndustryId/list",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" + SYSTEM_CONSTANTS.JOBS.IndustryId
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const jobsLevel = createAsyncThunk(
  "jobs/Level/list",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value/" + SYSTEM_CONSTANTS.JOBS.Level
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const profileTechSkillsList = createAsyncThunk(
  "admin/backend-value/candidate/profile/tech-skills/list",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value-category/" +
        SYSTEM_CONSTANTS.CANDIDATES.Profile.Skills.TechSkills.box +
        "/" +
        SYSTEM_CONSTANTS.CANDIDATES.Profile.Skills.TechSkills.id
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const profileJobSkillsList = createAsyncThunk(
  "admin/backend-value/candidate/profile/job-skills/list",
  async (thunkAPI) => {
    const res = await GetData(
      true,
      "/admin/backend-value-category/" +
        SYSTEM_CONSTANTS.CANDIDATES.Profile.Skills.JobProfileSkills.box +
        "/" +
        SYSTEM_CONSTANTS.CANDIDATES.Profile.Skills.JobProfileSkills.id
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getNotificationCount = createAsyncThunk(
  "mailbox/internal-mails/notification-count",
  async (thunkAPI) => {
    const res = await GetData(true, "/internal-mails/notification-count");
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

/*************JOBS END*****************/

const layoutSlice = createSlice({
  name: "commonList",
  initialState: {
    joinedCaseStateList: [],
    applicationListData: [],
    applicationListStatus: [],
    qualifiedCaseStateList: [],
    joinedCaseProgressList: [],
    qualifiedCaseProgressList: [],
    joinedCaseStageList: [],
    finalFeeStatusList: [],
    qualifiedCaseStageList: [],
    finalFeeProgressList: [],
    finalFeeStageList: [],
    interviewStatusList: [],
    jobOfferStatusList: [],
    jobOfferProgressList: [],
    jobOfferStageList: [],
    interviewProgressList: [],
    interviewStageList: [],
    reasionList: [],
    qualifiedReasionList: [],
    //jobOfferReasionList: [],
    profileStatusList: [],
    caseStatusList: [],
    caseProgressList: [],
    caseStageList: [],
    candidateCategoryList: [],
    candidateNationalityList: [],
    candidateVisaStatusList: [],
    candidateNativeList: [],
    candidateFluentList: [],
    candidateIntermediateList: [],
    candidateCVSourceList: [],
    candidateGenderList: [],
    candidateIndustryList: [],
    candidateCareerLevelList: [],
    candidateCityList: [],
    candidateCountryList: [],
    candidateLatestJobList: [],
    candidateEduationList: [],
    candidateCareerPriorityList: [],

    resourceCategoryList: [],
    resourceCoverageList: [],
    resourcePaidUnpaidList: [],
    resourceSourceTypeList: [],
    resourceStatusList: [],

    resourceLoginCategoryList: [],
    resourceLoginSourceTypeList: [],

    adsJobboardList: [],
    adsCompanyList: [],
    adsLanguageList: [],
    adsRoleTypeList: [],

    jobsCategoryList: [],
    jobsCompanyList: [],
    jobsCountList: [],
    jobsProjectList: [],
    jobsRoleTypeList: [],
    jobsLanguageList: [],
    jobsCurrencyList: [],
    jobsLocalityList: [],
    jobsNoofJobsList: [],
    jobsCountryList: [],
    jobsCityList: [],
    jobsJobProfileList: [],
    jobsWorkPermitList: [],

    jobsWorkplaceList: [],
    jobsWorkdaysList: [],
    jobsShiftsWorkList: [],
    jobsContracttypeList: [],
    jobsSalarytypeList: [],
    jobsAvailabilityList: [],
    jobsOutsideofeuList: [],
    jobsFlightticketList: [],
    jobsTaxifromairportList: [],
    jobsAccommodationtList: [],
    jobsRelocationbudgetList: [],
    jobsRelocationList: [],
    jobsWorkTypeList: [],
    jobsExperiencetList: [],
    jobssInterviewsLit: [],
    jobsMethodInterviewsLit: [],
    jobsSendoutMethodLits: [],
    jobsSendoutStepLits: [],
    jobStatusList: [],
    jobsItskillList: [],
    jobskillList: [],
    jobsIndustryIdList: [],
    jobsLevelIdList: [],
    candidateDocTypeList: [],

    candidateProfileTechAllList: [],
    candidatProfileJobAllList: [],
    filterRecId: [],
    isError: false,
    message: "",
    notificationCount: 0,
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(pipelineClearSummaryRcFilter.pending, (state) => {});
    builder.addCase(
      pipelineClearSummaryRcFilter.fulfilled,
      (state, { payload }) => {
        state.filterRecId = payload.result;
      }
    );
    builder.addCase(pipelineClearSummaryRcFilter.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(pipelineSummaryRcFilter.pending, (state) => {});
    builder.addCase(pipelineSummaryRcFilter.fulfilled, (state, { payload }) => {
      state.filterRecId = payload.result;
    });
    builder.addCase(pipelineSummaryRcFilter.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(getFileTypeList.pending, (state) => {});
    builder.addCase(getFileTypeList.fulfilled, (state, { payload }) => {
      state.candidateDocTypeList = payload.result;
    });
    builder.addCase(getFileTypeList.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(joinedCaseStage.pending, (state) => {});
    builder.addCase(joinedCaseStage.fulfilled, (state, { payload }) => {
      state.joinedCaseStageList = payload.result;
    });
    builder.addCase(joinedCaseStage.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(qualifiedCaseStage.pending, (state) => {});
    builder.addCase(qualifiedCaseStage.fulfilled, (state, { payload }) => {
      state.qualifiedCaseStageList = payload.result;
    });
    builder.addCase(qualifiedCaseStage.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(finalFeeCaseStatus.pending, (state) => {});
    builder.addCase(finalFeeCaseStatus.fulfilled, (state, { payload }) => {
      state.finalFeeStatusList = payload.result;
    });
    builder.addCase(finalFeeCaseStatus.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(finalFeeCaseProgress.pending, (state) => {});
    builder.addCase(finalFeeCaseProgress.fulfilled, (state, { payload }) => {
      state.finalFeeProgressList = payload.result;
    });
    builder.addCase(finalFeeCaseProgress.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(finalFeeCaseStage.pending, (state) => {});
    builder.addCase(finalFeeCaseStage.fulfilled, (state, { payload }) => {
      state.finalFeeStageList = payload.result;
    });
    builder.addCase(finalFeeCaseStage.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(joinedCaseProgress.pending, (state) => {});
    builder.addCase(joinedCaseProgress.fulfilled, (state, { payload }) => {
      state.joinedCaseProgressList = payload.result;
    });
    builder.addCase(joinedCaseProgress.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });
    builder.addCase(qualifiedCaseProgress.pending, (state) => {});
    builder.addCase(qualifiedCaseProgress.fulfilled, (state, { payload }) => {
      state.qualifiedCaseProgressList = payload.result;
    });
    builder.addCase(qualifiedCaseProgress.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(joinedCaseStatus.pending, (state) => {});
    builder.addCase(joinedCaseStatus.fulfilled, (state, { payload }) => {
      state.joinedCaseStateList = payload.result;
    });
    builder.addCase(joinedCaseStatus.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(qualifiedCaseStatus.pending, (state) => {});
    builder.addCase(qualifiedCaseStatus.fulfilled, (state, { payload }) => {
      state.qualifiedCaseStateList = payload.result;
    });
    builder.addCase(qualifiedCaseStatus.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(jobOffersCaseStage.pending, (state) => {});
    builder.addCase(jobOffersCaseStage.fulfilled, (state, { payload }) => {
      state.jobOfferStageList = payload.result;
    });
    builder.addCase(jobOffersCaseStage.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(jobOffersCaseProgress.pending, (state) => {});
    builder.addCase(jobOffersCaseProgress.fulfilled, (state, { payload }) => {
      state.jobOfferProgressList = payload.result;
    });
    builder.addCase(jobOffersCaseProgress.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(jobOffersCaseStatus.pending, (state) => {});
    builder.addCase(jobOffersCaseStatus.fulfilled, (state, { payload }) => {
      state.jobOfferStatusList = payload.result;
    });
    builder.addCase(jobOffersCaseStatus.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(interviewCaseStatus.pending, (state) => {});
    builder.addCase(interviewCaseStatus.fulfilled, (state, { payload }) => {
      state.interviewStatusList = payload.result;
    });
    builder.addCase(interviewCaseStatus.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(profilesStatus.pending, (state) => {});
    builder.addCase(profilesStatus.fulfilled, (state, { payload }) => {
      state.profileStatusList = payload.result;
    });
    builder.addCase(profilesStatus.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(caseStatus.pending, (state) => {});
    builder.addCase(caseStatus.fulfilled, (state, { payload }) => {
      state.caseStatusList = payload.result;
    });
    builder.addCase(caseStatus.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(caseProgress.pending, (state) => {});
    builder.addCase(caseProgress.fulfilled, (state, { payload }) => {
      state.caseProgressList = payload.result;
    });
    builder.addCase(caseProgress.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(interviewCaseProgress.pending, (state) => {});
    builder.addCase(interviewCaseProgress.fulfilled, (state, { payload }) => {
      state.interviewProgressList = payload.result;
    });
    builder.addCase(interviewCaseProgress.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(reasionCase.pending, (state) => {});
    builder.addCase(reasionCase.fulfilled, (state, { payload }) => {
      state.reasionList = payload.result;
    });
    builder.addCase(reasionCase.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    // builder.addCase(
    //     jobOffersReasionCase.pending, (state) => {
    //     });
    // builder.addCase(
    //     jobOffersReasionCase.fulfilled, (state, { payload }) => {
    //         state.jobOfferReasionList = payload.result
    //     });
    // builder.addCase(
    //     jobOffersReasionCase.rejected, (state, action) => {
    //         state.isError = true;
    //         state.message = 'Something went wrong';
    //     });

    builder.addCase(qualifiedReasionCase.pending, (state) => {});
    builder.addCase(qualifiedReasionCase.fulfilled, (state, { payload }) => {
      state.qualifiedReasionList = payload.result;
    });
    builder.addCase(qualifiedReasionCase.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(interviewCaseStage.pending, (state) => {});
    builder.addCase(interviewCaseStage.fulfilled, (state, { payload }) => {
      state.interviewStageList = payload.result;
    });
    builder.addCase(interviewCaseStage.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(caseStage.pending, (state) => {});
    builder.addCase(caseStage.fulfilled, (state, { payload }) => {
      state.caseStageList = payload.result;
    });
    builder.addCase(caseStage.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(candidateCategory.pending, (state) => {});
    builder.addCase(candidateCategory.fulfilled, (state, { payload }) => {
      state.candidateCategoryList = payload.result;
    });
    builder.addCase(candidateCategory.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(candidateNationality.pending, (state) => {});
    builder.addCase(candidateNationality.fulfilled, (state, { payload }) => {
      state.candidateNationalityList = payload.result;
    });
    builder.addCase(candidateNationality.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(candidateVisaStatus.pending, (state) => {});
    builder.addCase(candidateVisaStatus.fulfilled, (state, { payload }) => {
      state.candidateVisaStatusList = payload.result;
    });
    builder.addCase(candidateVisaStatus.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(candidateNative.pending, (state) => {});
    builder.addCase(candidateNative.fulfilled, (state, { payload }) => {
      state.candidateNativeList = payload.result;
    });
    builder.addCase(candidateNative.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(candidateFluent.pending, (state) => {});
    builder.addCase(candidateFluent.fulfilled, (state, { payload }) => {
      state.candidateFluentList = payload.result;
    });
    builder.addCase(candidateFluent.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(candidateIntermediate.pending, (state) => {});
    builder.addCase(candidateIntermediate.fulfilled, (state, { payload }) => {
      state.candidateIntermediateList = payload.result;
    });
    builder.addCase(candidateIntermediate.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(candidateCVSource.pending, (state) => {});
    builder.addCase(candidateCVSource.fulfilled, (state, { payload }) => {
      state.candidateCVSourceList = payload.result;
    });
    builder.addCase(candidateCVSource.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(candidateGender.pending, (state) => {});
    builder.addCase(candidateGender.fulfilled, (state, { payload }) => {
      state.candidateGenderList = payload.result;
    });
    builder.addCase(candidateGender.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(candidateIndustry.pending, (state) => {});
    builder.addCase(candidateIndustry.fulfilled, (state, { payload }) => {
      state.candidateIndustryList = payload.result;
    });
    builder.addCase(candidateIndustry.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(candidateCareerLevel.pending, (state) => {});
    builder.addCase(candidateCareerLevel.fulfilled, (state, { payload }) => {
      state.candidateCareerLevelList = payload.result;
    });
    builder.addCase(candidateCareerLevel.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(candidateCityLevel.pending, (state) => {});
    builder.addCase(candidateCityLevel.fulfilled, (state, { payload }) => {
      state.candidateCityList = payload.result;
    });
    builder.addCase(candidateCityLevel.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(candidateCountryLevel.pending, (state) => {});
    builder.addCase(candidateCountryLevel.fulfilled, (state, { payload }) => {
      state.candidateCountryList = payload.result;
    });
    builder.addCase(candidateCountryLevel.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(candidateLatestJob.pending, (state) => {});
    builder.addCase(candidateLatestJob.fulfilled, (state, { payload }) => {
      state.candidateLatestJobList = payload.result;
    });
    builder.addCase(candidateLatestJob.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(candidateEduation.pending, (state) => {});
    builder.addCase(candidateEduation.fulfilled, (state, { payload }) => {
      state.candidateEduationList = payload.result;
    });
    builder.addCase(candidateEduation.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(candidateCareerPriority.pending, (state) => {});
    builder.addCase(candidateCareerPriority.fulfilled, (state, { payload }) => {
      state.candidateCareerPriorityList = payload.result;
    });
    builder.addCase(candidateCareerPriority.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(resourceCategory.pending, (state) => {});
    builder.addCase(resourceCategory.fulfilled, (state, { payload }) => {
      state.resourceCategoryList = payload.result;
    });
    builder.addCase(resourceCategory.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(resourceCoverage.pending, (state) => {});
    builder.addCase(resourceCoverage.fulfilled, (state, { payload }) => {
      state.resourceCoverageList = payload.result;
    });
    builder.addCase(resourceCoverage.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });
    builder.addCase(resourcePaidUnpaid.pending, (state) => {});
    builder.addCase(resourcePaidUnpaid.fulfilled, (state, { payload }) => {
      state.resourcePaidUnpaidList = payload.result;
    });
    builder.addCase(resourcePaidUnpaid.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });
    builder.addCase(resourceSourceType.pending, (state) => {});
    builder.addCase(resourceSourceType.fulfilled, (state, { payload }) => {
      state.resourceSourceTypeList = payload.result;
    });
    builder.addCase(resourceSourceType.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });
    builder.addCase(resourceStatus.pending, (state) => {});
    builder.addCase(resourceStatus.fulfilled, (state, { payload }) => {
      state.resourceStatusList = payload.result;
    });
    builder.addCase(resourceStatus.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(resourceLoginCategory.pending, (state) => {});
    builder.addCase(resourceLoginCategory.fulfilled, (state, { payload }) => {
      state.resourceLoginCategoryList = payload.result;
    });
    builder.addCase(resourceLoginCategory.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });
    builder.addCase(resourceLoginSourceType.pending, (state) => {});
    builder.addCase(resourceLoginSourceType.fulfilled, (state, { payload }) => {
      state.resourceLoginSourceTypeList = payload.result;
    });
    builder.addCase(resourceLoginSourceType.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(adsJobboard.pending, (state) => {});
    builder.addCase(adsJobboard.fulfilled, (state, { payload }) => {
      state.adsJobboardList = payload.result;
    });
    builder.addCase(adsJobboard.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(adsCompany.pending, (state) => {});
    builder.addCase(adsCompany.fulfilled, (state, { payload }) => {
      state.adsCompanyList = payload.result;
    });
    builder.addCase(adsCompany.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(adsLanguage.pending, (state) => {});
    builder.addCase(adsLanguage.fulfilled, (state, { payload }) => {
      state.adsLanguageList = payload.result;
    });
    builder.addCase(adsLanguage.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(adsRoleType.pending, (state) => {});
    builder.addCase(adsRoleType.fulfilled, (state, { payload }) => {
      state.adsRoleTypeList = payload.result;
    });
    builder.addCase(adsRoleType.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(jobsCategory.pending, (state) => {});
    builder.addCase(jobsCategory.fulfilled, (state, { payload }) => {
      state.jobsCategoryList = payload.result;
    });
    builder.addCase(jobsCategory.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(jobsCompany.pending, (state) => {});
    builder.addCase(jobsCompany.fulfilled, (state, { payload }) => {
      state.jobsCompanyList = payload.result;
    });
    builder.addCase(jobsCompany.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(jobsCount.pending, (state) => {});
    builder.addCase(jobsCount.fulfilled, (state, { payload }) => {
      state.jobsCountList = payload.result;
    });
    builder.addCase(jobsCount.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(applicationList.fulfilled, (state, { payload }) => {
      state.applicationListData = payload.result;
      state.applicationListStatus = {
        newCase: payload.newCase,
        inProgress: payload.inProgress,
        closed: payload.closed,
      };
      state.message = payload.message;
      state.isLoading = false;
    });
    builder.addCase(applicationList.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(jobsProject.pending, (state) => {});
    builder.addCase(jobsProject.fulfilled, (state, { payload }) => {
      state.jobsProjectList = payload.result;
    });
    builder.addCase(jobsProject.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(jobsRoleType.pending, (state) => {});
    builder.addCase(jobsRoleType.fulfilled, (state, { payload }) => {
      state.jobsRoleTypeList = payload.result;
    });
    builder.addCase(jobsRoleType.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(jobsLocality.pending, (state) => {});
    builder.addCase(jobsLocality.fulfilled, (state, { payload }) => {
      state.jobsLocalityList = payload.result;
    });
    builder.addCase(jobsLocality.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(jobsNoOfJobs.pending, (state) => {});
    builder.addCase(jobsNoOfJobs.fulfilled, (state, { payload }) => {
      state.jobsNoofJobsList = payload.result;
    });
    builder.addCase(jobsNoOfJobs.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(jobsCountry.pending, (state) => {});
    builder.addCase(jobsCountry.fulfilled, (state, { payload }) => {
      state.jobsCountryList = payload.result;
    });
    builder.addCase(jobsCountry.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(jobsCity.pending, (state) => {});
    builder.addCase(jobsCity.fulfilled, (state, { payload }) => {
      state.jobsCityList = payload.result;
    });
    builder.addCase(jobsCity.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(countryCurrency.pending, (state) => {});
    builder.addCase(countryCurrency.fulfilled, (state, { payload }) => {
      state.jobsCurrencyList = payload.result;
    });
    builder.addCase(countryCurrency.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(jobsLanguages.pending, (state) => {});
    builder.addCase(jobsLanguages.fulfilled, (state, { payload }) => {
      state.jobsLanguageList = payload.result;
    });
    builder.addCase(jobsLanguages.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(jobsJobProfile.pending, (state) => {});
    builder.addCase(jobsJobProfile.fulfilled, (state, { payload }) => {
      state.jobsJobProfileList = payload.result;
    });
    builder.addCase(jobsJobProfile.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(jobsWorkPermit.pending, (state) => {});
    builder.addCase(jobsWorkPermit.fulfilled, (state, { payload }) => {
      state.jobsWorkPermitList = payload.result;
    });
    builder.addCase(jobsWorkPermit.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(jobsWorkplace.pending, (state) => {});
    builder.addCase(jobsWorkplace.fulfilled, (state, { payload }) => {
      state.jobsWorkplaceList = payload.result;
    });
    builder.addCase(jobsWorkplace.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(jobsWorkdays.pending, (state) => {});
    builder.addCase(jobsWorkdays.fulfilled, (state, { payload }) => {
      state.jobsWorkdaysList = payload.result;
    });
    builder.addCase(jobsWorkdays.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(jobsShiftsWork.pending, (state) => {});
    builder.addCase(jobsShiftsWork.fulfilled, (state, { payload }) => {
      state.jobsShiftsWorkList = payload.result;
    });
    builder.addCase(jobsShiftsWork.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(jobsContracttype.pending, (state) => {});
    builder.addCase(jobsContracttype.fulfilled, (state, { payload }) => {
      state.jobsContracttypeList = payload.result;
    });
    builder.addCase(jobsContracttype.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(jobsSalarytype.pending, (state) => {});
    builder.addCase(jobsSalarytype.fulfilled, (state, { payload }) => {
      state.jobsSalarytypeList = payload.result;
    });
    builder.addCase(jobsSalarytype.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(jobsAvailability.pending, (state) => {});
    builder.addCase(jobsAvailability.fulfilled, (state, { payload }) => {
      state.jobsAvailabilityList = payload.result;
    });
    builder.addCase(jobsAvailability.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(jobsOutsideofeu.pending, (state) => {});
    builder.addCase(jobsOutsideofeu.fulfilled, (state, { payload }) => {
      state.jobsOutsideofeuList = payload.result;
    });
    builder.addCase(jobsOutsideofeu.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(jobsFlightticket.pending, (state) => {});
    builder.addCase(jobsFlightticket.fulfilled, (state, { payload }) => {
      state.jobsFlightticketList = payload.result;
    });
    builder.addCase(jobsFlightticket.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(jobsTaxifromairport.pending, (state) => {});
    builder.addCase(jobsTaxifromairport.fulfilled, (state, { payload }) => {
      state.jobsTaxifromairportList = payload.result;
    });
    builder.addCase(jobsTaxifromairport.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(jobsAccommodationt.pending, (state) => {});
    builder.addCase(jobsAccommodationt.fulfilled, (state, { payload }) => {
      state.jobsAccommodationtList = payload.result;
    });
    builder.addCase(jobsAccommodationt.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(jobWorktype.pending, (state) => {});
    builder.addCase(jobWorktype.fulfilled, (state, { payload }) => {
      state.jobsWorkTypeList = payload.result;
    });
    builder.addCase(jobWorktype.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });
    builder.addCase(jobRelocation.pending, (state) => {});
    builder.addCase(jobRelocation.fulfilled, (state, { payload }) => {
      state.jobsRelocationList = payload.result;
    });
    builder.addCase(jobRelocation.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(jobsRelocationbudget.pending, (state) => {});
    builder.addCase(jobsRelocationbudget.fulfilled, (state, { payload }) => {
      state.jobsRelocationbudgetList = payload.result;
    });
    builder.addCase(jobsRelocationbudget.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(jobsskills.pending, (state) => {});
    builder.addCase(jobsskills.fulfilled, (state, { payload }) => {
      state.jobskillList = payload.result;
    });
    builder.addCase(jobsskills.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(jobitskills.pending, (state) => {});
    builder.addCase(jobitskills.fulfilled, (state, { payload }) => {
      state.jobsItskillList = payload.result;
    });
    builder.addCase(jobitskills.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(jobsStatusId.pending, (state) => {});
    builder.addCase(jobsStatusId.fulfilled, (state, { payload }) => {
      state.jobStatusList = payload.result;
    });
    builder.addCase(jobsStatusId.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(jobssendoutStepId.pending, (state) => {});
    builder.addCase(jobssendoutStepId.fulfilled, (state, { payload }) => {
      state.jobsSendoutStepLits = payload.result;
    });
    builder.addCase(jobssendoutStepId.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(jobsSendoutsMethod.pending, (state) => {});
    builder.addCase(jobsSendoutsMethod.fulfilled, (state, { payload }) => {
      state.jobsSendoutMethodLits = payload.result;
    });
    builder.addCase(jobsSendoutsMethod.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(jobsInterviewsMethod.pending, (state) => {});
    builder.addCase(jobsInterviewsMethod.fulfilled, (state, { payload }) => {
      state.jobsMethodInterviewsLit = payload.result;
    });
    builder.addCase(jobsInterviewsMethod.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(jobssInterviews.pending, (state) => {});
    builder.addCase(jobssInterviews.fulfilled, (state, { payload }) => {
      state.jobssInterviewsLit = payload.result;
    });
    builder.addCase(jobssInterviews.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(jobsExperiencet.pending, (state) => {});
    builder.addCase(jobsExperiencet.fulfilled, (state, { payload }) => {
      state.jobsExperiencetList = payload.result;
    });
    builder.addCase(jobsExperiencet.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(jobsIndustryId.pending, (state) => {});
    builder.addCase(jobsIndustryId.fulfilled, (state, { payload }) => {
      state.jobsIndustryIdList = payload.result;
    });
    builder.addCase(jobsIndustryId.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(jobsLevel.pending, (state) => {});
    builder.addCase(jobsLevel.fulfilled, (state, { payload }) => {
      state.jobsLevelIdList = payload.result;
    });
    builder.addCase(jobsLevel.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(profileTechSkillsList.pending, (state) => {});
    builder.addCase(profileTechSkillsList.fulfilled, (state, { payload }) => {
      state.candidateProfileTechAllList = payload.result;
    });
    builder.addCase(profileTechSkillsList.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(profileJobSkillsList.pending, (state) => {});
    builder.addCase(profileJobSkillsList.fulfilled, (state, { payload }) => {
      state.candidatProfileJobAllList = payload.result;
    });
    builder.addCase(profileJobSkillsList.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(getNotificationCount.pending, (state) => {});
    builder.addCase(getNotificationCount.fulfilled, (state, { payload }) => {
      state.notificationCount = payload.data;
    });
    builder.addCase(getNotificationCount.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });
  },
});
export const { clearState } = layoutSlice.actions;
export default layoutSlice.reducer;
