import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GetData, PostData, PutData, addCandidate, DeleteData } from "../../services";

export const pipelineNumbersCounts = createAsyncThunk(
    'candidate/pipeline/number-count',
    async ({ }, thunkAPI) => {
        const res = await GetData(true, '/candidates/list/pipeline/number-count')
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const addNewCandidate = createAsyncThunk(
    'candidate/addnew',
    async ({ category, c_country, c_city, nationality, visaStatus, native1, fluent1, cv_source, fileName }, thunkAPI) => {
        const res = await addCandidate(true, '/candidates/profile/addnew/', { category, c_country, c_city, nationality, visaStatus, native1, fluent1, cv_source, fileName })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const moveCandidateRecords = createAsyncThunk(
    'candidate/move-records',
    async ({ ids, status }, thunkAPI) => {
        const res = await PostData(true, '/candidates/profile/move-records', { ids, status })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const updateTable = createAsyncThunk(
    'candidate/list/update',
    async ({ id, key, value }, thunkAPI) => {
        const res = await PutData(true, '/candidates/profile/' + id, { key, value })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const candidateFilterSummaryList = createAsyncThunk(
    'candidate/pipeline/summary-filter-list',
    async ({ rec_id }, thunkAPI) => {
        const res = await PostData(true, '/candidates/list/pipeline/list/summaryRecFilter/' + rec_id)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const databaseSearchList = createAsyncThunk(
    'candidate/database/search/list',
    async ({ filter }, thunkAPI) => {
        const res = await PostData(true, "/candidates/list/pipeline/list/dbsearch", { filter });
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const candidateSummaryList = createAsyncThunk(
    'candidate/pipeline/summary-list',
    async (thunkAPI) => {
        const res = await PostData(true, '/candidates/list/pipeline/list')
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const candidateList = createAsyncThunk(
    'candidate/pipeline/list',
    async (thunkAPI) => {
        const res = await GetData(true, '/candidates/list/pipeline/all')
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const addCandidateManually = createAsyncThunk(
    'candidate/add-manual',
    async (thunkAPI) => {
        const res = await PostData(true, '/candidates/profile/add-manual', {})
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

// export const updateCommentList = createAsyncThunk(
//     'candidate/commentlist/update-comment',
//     async ({ id, value }, thunkAPI) => {
//         const res = await PutData(true, '/candidates/profile/update-comment/' + id, { value })
//         if (res.status === 200) {
//             return res.payload
//         } else {
//             return thunkAPI.rejectWithValue();
//         }
//     }
// );

export const updateComment = createAsyncThunk(
    'candidate/list/update-comment',
    async ({ id, value }, thunkAPI) => {
        const res = await PutData(true, '/candidates/profile/update-comment/' + id, { value })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const duplicateCheck = createAsyncThunk(
    'candidate/duplicate-check',
    async ({ name, email, phone }, thunkAPI) => {
        const res = await PostData(true, '/duplicate-check', { name, email, phone })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const candidateProfileDetails = createAsyncThunk(
    'candidate/profile/details',
    async ({ id }, thunkAPI) => {
        const res = await GetData(true, '/candidates/profile/' + id)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const candidateProfileRecruiterDetails = createAsyncThunk(
    'candidate/profile/rec-details',
    async ({ id }, thunkAPI) => {
        const res = await GetData(true, '/candidates/profile/rec-details/' + id)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const candidateProfileJobDetails = createAsyncThunk(
    'candidate/profile/job-details',
    async ({ id }, thunkAPI) => {
        const res = await GetData(true, '/candidates/profile/job-details/' + id)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const candidateProfileLanguageDetails = createAsyncThunk(
    'candidate/profile/language-details',
    async ({ id }, thunkAPI) => {
        const res = await GetData(true, '/candidates/profile/language-details/' + id)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const getDocments = createAsyncThunk(
    'candidate/profile/documents',
    async ({ id }, thunkAPI) => {
        const res = await GetData(true, '/candidates/profile/documents/' + id)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);


export const updateDocuments = createAsyncThunk(
    'candidate/profile/documents-update',
    async ({ id, fileName, fileType, onlyfileName }, thunkAPI) => {
        const res = await PutData(true, '/candidates/profile/update-candidate-docs/' + id, { fileName, fileType, onlyfileName })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);


export const apiParsing = createAsyncThunk(
    'candidate/add/api-parsing',
    async ({ profileIndicator, flag_type, profileStatus, jobId, resource_team, rec_admin, rec_id, am_id, name, email1, phone1, caseStatus, case_progress, case_stage, category, fileUrl }, thunkAPI) => {
        const res = await PostData(true, '/candidates/profile/api-parsing', { profileIndicator, flag_type, profileStatus, jobId, resource_team, rec_admin, rec_id, am_id, name, email1, phone1, caseStatus, case_progress, case_stage, category, fileUrl })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)


export const approvelNumbersCounts = createAsyncThunk(
    'candidate/summary/approvelCount',
    async ({ filter }, thunkAPI) => {
        const res = await PostData(true, '/candidates/database/summary/approvel', { filter })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const deleteCv = createAsyncThunk(
    'candidate/profile/delete-cv',
    async ({ id }, thunkAPI) => {
        const res = await PutData(true, '/candidates/profile/delete-docs/' + id)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);


export const updateFileType = createAsyncThunk(
    'candidate/profile/update-filetype',
    async ({ id, fileType }, thunkAPI) => {
        const res = await PutData(true, '/candidates/profile/file-type/' + id, { fileType })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const listAllTags = createAsyncThunk(
    'candidates/profile/tags/category/list',
    async ({ id }, thunkAPI) => {
        const res = await GetData(true, '/admin/backend-value/' + id)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const listSelectedTags = createAsyncThunk(
    'candidates/profile/tags/category/selected/list',
    async ({ canId, category }, thunkAPI) => {
        const res = await GetData(true, '/candidates/profile/tech-skills/' + canId + '/' + category)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)
export const candidateAllTechSkillsTag = createAsyncThunk(
    'candidates/profile/techskills/tags/list',
    async ({ id }, thunkAPI) => {
        const res = await GetData(true, '/candidates/profile/tech-skills/' + id)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)
export const candidateAllJobProfileSkillsTag = createAsyncThunk(
    'candidates/profile/jobProfileskills/tags/list',
    async ({ id }, thunkAPI) => {
        const res = await GetData(true, '/candidates/profile/job-profile-skills/' + id)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const profileListSelectedTags = createAsyncThunk(
    'candidates/jobProfile/tags/category/selected/list',
    async ({ canId, category }, thunkAPI) => {
        const res = await GetData(true, '/candidates/profile/job-profile-skills/' + canId + '/' + category)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const addTechTags = createAsyncThunk(
    'candidates/profile/tags/category/selected/add',
    async ({ canId, category, value }, thunkAPI) => {
        const res = await PostData(true, '/candidates/profile/tech-skills/', { canId, category, value })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)
export const addJobProfileTags = createAsyncThunk(
    'candidates/jobprofile/tags/category/selected/add',
    async ({ canId, category, value }, thunkAPI) => {
        const res = await PostData(true, '/candidates/profile/job-profile-skills/', { canId, category, value })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const removeTechTags = createAsyncThunk(
    'candidates/profile/tags/category/selected/delete',
    async ({ id }, thunkAPI) => {
        const res = await DeleteData(true, '/candidates/profile/tech-skills/' + id)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const removeJobProfileTags = createAsyncThunk(
    'candidates/jobprofile/tags/category/selected/delete',
    async ({ id }, thunkAPI) => {
        const res = await DeleteData(true, '/candidates/profile/job-profile-skills/' + id)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const deleteCandidateAc = createAsyncThunk(
    'candidate/delete/candidate',
    async ({ id, key, value }, thunkAPI) => {
        const res = await PutData(true, '/candidates/list/pipeline/delete-ac/' + id, { key, value })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

const CandidateSlice = createSlice({
    name: 'newcvList',
    initialState: {
        numberList: [],
        candidateDataList: [],
        candidateDataSummaryList: [],
        databaseSearchCvList: [],
        candidateDocumentsList: [],

        duplicateList: [],
        profileDetails: {},
        profileRecDetails: {},
        profileJobDetails: {},
        profileLangaugeDetails: {},
        isLoading: false,
        isProfileTabLoading: false,
        isProfileLoading: false,
        isProfileRecLoading: false,
        isDuplicateLoading: false,
        isDocumentUpdate: false,
        isFileTypeUpdate: false,
        isDocumentDelete: false,
        isError: false,
        isSuccess: false,
        isAPIParseSuccess: false,
        isMoved: false,
        message: '',
        approvelCount: [],
        tagsListTechSkills: [],
        tagsListJobProfileSkills: [],
        preTagsList: [],
        tagsList: [],
        jobProfiletagsList: [],
        isPreTagsLoading: false,
        isTagsLoading: false,
        isProfileTagsLoading: false,
        isDeleteAc: false,
    },
    reducers: {
        clearState: (state) => {
            state.isDeleteAc = false;
            state.isError = false;
            state.isLoading = false;
            state.isProfileTabLoading = false;
            state.isProfileLoading = false;
            state.isProfileRecLoading = false;
            state.isProfileJobLoading = false;
            state.isProfileLanguageLoading = false;
            state.isDuplicateLoading = false;
            state.isDocumentUpdate = false;
            state.isFileTypeUpdate = false;
            state.isDocumentDelete = false;
            state.isSuccess = false;
            state.isAPIParseSuccess = false;
            state.isMoved = false;
            state.isPreTagsLoading = false;
            state.isTagsLoading = false;
            state.message = '';
        },
        updateProfileDetails: (state, action) => {
            const key = action.payload.key
            const value = action.payload.value
            state.profileDetails = ({ ...state.profileDetails, [key]: value })
        },
        updateRa: (state, { payload }) => {

            let mainArray = state.candidateDataList;
            let finalResult = mainArray.map((r) => {
                if (r.id === payload.id) {
                    return ({ ...r, recAdminInfo: payload })
                } else {
                    return r
                }
            })
            state.candidateDataList = finalResult;
        },
        updateRc: (state, { payload }) => {
            let mainArray = state.candidateDataList;
            let finalResult = mainArray.map((r) => {
                if (r.id === payload.id) {
                    return ({ ...r, recInfo: payload })
                } else {
                    return r
                }
            })
            state.candidateDataList = finalResult;
        },
        updateAm: (state, { payload }) => {
            let mainArray = state.candidateDataList;
            let finalResult = mainArray.map((r) => {
                if (r.id === payload.id) {
                    return ({ ...r, amInfo: payload })
                } else {
                    return r
                }
            })
            state.candidateDataList = finalResult;
        },
        updateOtherdata: (state, { payload }) => {

            let mainArray = state.candidateDataList;
            let finalResult = mainArray.map((r) => {
                if (r.id === payload.id) {
                    return ({ ...r, [payload.columnId]: payload.value })
                } else {
                    return r
                }
            })
            state.candidateDataList = finalResult;
        }
    },
    extraReducers: (builder) => {

        builder.addCase(deleteCandidateAc.pending, (state) => {
            state.isDeleteAc = false;
            state.isError = false;
        });
        builder.addCase(
            deleteCandidateAc.fulfilled, (state, { payload }) => {
                state.isDeleteAc = true;
                state.isError = false;
            });
        builder.addCase(
            deleteCandidateAc.rejected, (state, action) => {
                state.isDeleteAc = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            approvelNumbersCounts.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            approvelNumbersCounts.fulfilled, (state, { payload }) => {
                state.approvelCount = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            approvelNumbersCounts.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            addCandidateManually.pending, (state) => {
            });
        builder.addCase(
            addCandidateManually.fulfilled, (state, { payload }) => {
                state.candidateDataList.unshift(payload.result)
                state.isSuccess = true;
            });
        builder.addCase(
            addCandidateManually.rejected, (state, action) => {
                state.isSuccess = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            apiParsing.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            apiParsing.fulfilled, (state, { payload }) => {
                state.candidateDataList.unshift(payload.result)
                state.isAPIParseSuccess = true;
            });
        builder.addCase(
            apiParsing.rejected, (state, action) => {
                state.isLoading = false;
                state.isAPIParseSuccess = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            updateTable.pending, (state) => {
            });
        builder.addCase(
            updateTable.fulfilled, (state, { payload }) => {
                let mainArray = state.candidateDataList;
                let finalResult = mainArray.map((r) => {
                    if (r.id === payload.result.id) {
                        return (payload.result)
                    } else {
                        return r
                    }
                })
                state.isUpdate = true
                state.candidateDataList = finalResult;
            });
        builder.addCase(
            updateTable.rejected, (state, action) => {
                state.isError = true;
                // state.message = action.error.message;
            });

        builder.addCase(
            candidateList.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            candidateList.fulfilled, (state, { payload }) => {
                state.candidateDataList = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            candidateList.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            candidateFilterSummaryList.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            candidateFilterSummaryList.fulfilled, (state, { payload }) => {
                state.candidateDataSummaryList = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            candidateFilterSummaryList.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            databaseSearchList.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            databaseSearchList.fulfilled, (state, { payload }) => {
                state.databaseSearchCvList = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            databaseSearchList.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });
        builder.addCase(
            candidateSummaryList.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            candidateSummaryList.fulfilled, (state, { payload }) => {
                state.candidateDataSummaryList = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            candidateSummaryList.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            pipelineNumbersCounts.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            pipelineNumbersCounts.fulfilled, (state, { payload }) => {
                state.numberList = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            pipelineNumbersCounts.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            addNewCandidate.pending, (state) => {
                state.isLoading = true;
                state.isSuccess = false;
            });
        builder.addCase(
            addNewCandidate.fulfilled, (state, { payload }) => {
                state.candidateDataList.unshift(payload.result)
                state.message = payload.message;
                state.isSuccess = payload.status;
                state.isLoading = false;
            });
        builder.addCase(
            addNewCandidate.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            moveCandidateRecords.pending, (state) => {
            });
        builder.addCase(
            moveCandidateRecords.fulfilled, (state, action) => {
                action.meta.arg.ids.map((item) => {
                    let mainArray = state.candidateDataList;
                    let finalResult = mainArray.map((r) => {
                        if (r.id == item) {
                            return ({ ...r, status: action.meta.arg.status })
                        } else {
                            return r
                        }
                    })
                    return state.candidateDataList = finalResult
                })
                state.isMoved = true;
                state.message = 'Records moved successfully'
            });
        builder.addCase(
            moveCandidateRecords.rejected, (state, action) => {
                state.isMoved = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            updateComment.pending, (state) => {
            });
        builder.addCase(
            updateComment.fulfilled, (state, action) => {
                let mainArray = state.candidateDataList;
                let finalResult = mainArray.map((r) => {
                    if (r.canId === action.meta.arg.id) {
                        return ({ ...r, commentCount: action.meta.arg.value })
                    } else {
                        return r
                    }
                })
                state.isUpdate = true
                state.candidateDataList = finalResult
            });
        builder.addCase(
            updateComment.rejected, (state, action) => {
                state.isError = true;
                // state.message = action.error.message;
            });

        // builder.addCase(
        //     updateCommentList.pending, (state) => {
        //     });
        // builder.addCase(
        //     updateCommentList.fulfilled, (state, action) => {
        //         let mainArray = state.candidateDataList;
        //         let finalResult = mainArray.map((r) => {
        //             if (r.canId === action.meta.arg.id) {
        //                 return ({ ...r, commentCount: action.meta.arg.value })
        //             } else {
        //                 return r
        //             }
        //         })
        //         state.isUpdate = true
        //         state.candidateDataList = finalResult
        //     });
        // builder.addCase(
        //     updateCommentList.rejected, (state, action) => {
        //         state.isError = true;

        //     });

        builder.addCase(
            duplicateCheck.pending, (state) => {
                state.isDuplicateLoading = true;
            });
        builder.addCase(
            duplicateCheck.fulfilled, (state, { payload }) => {
                state.isDuplicateLoading = false;
                state.duplicateList = payload.result
            });
        builder.addCase(
            duplicateCheck.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            candidateProfileDetails.pending, (state) => {
                state.isProfileLoading = true;
            });
        builder.addCase(
            candidateProfileDetails.fulfilled, (state, { payload }) => {
                state.isProfileLoading = false;
                state.profileDetails = payload.result
            });
        builder.addCase(
            candidateProfileDetails.rejected, (state, action) => {
                state.isProfileLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            candidateProfileRecruiterDetails.pending, (state) => {
                state.isProfileRecLoading = true;
            });
        builder.addCase(
            candidateProfileRecruiterDetails.fulfilled, (state, { payload }) => {
                state.isProfileRecLoading = false;
                state.profileRecDetails = payload.result
            });
        builder.addCase(
            candidateProfileRecruiterDetails.rejected, (state, action) => {
                state.isProfileRecLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            candidateProfileJobDetails.pending, (state) => {
                state.isProfileJobLoading = true;
            });
        builder.addCase(
            candidateProfileJobDetails.fulfilled, (state, { payload }) => {
                state.isProfileJobLoading = false;
                state.profileJobDetails = payload.result
            });
        builder.addCase(
            candidateProfileJobDetails.rejected, (state, action) => {
                state.isProfileJobLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            candidateProfileLanguageDetails.pending, (state) => {
                state.isProfileLanguageLoading = true;
            });
        builder.addCase(
            candidateProfileLanguageDetails.fulfilled, (state, { payload }) => {
                state.isProfileLanguageLoading = false;
                state.profileLangaugeDetails = payload.result
            });
        builder.addCase(
            candidateProfileLanguageDetails.rejected, (state, action) => {
                state.isProfileLanguageLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            getDocments.pending, (state) => {
                state.isProfileTabLoading = true;
            });
        builder.addCase(
            getDocments.fulfilled, (state, { payload }) => {
                state.isProfileTabLoading = false;
                state.candidateDocumentsList = payload.result
            });
        builder.addCase(
            getDocments.rejected, (state, action) => {
                state.isProfileTabLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            updateDocuments.pending, (state) => {
                state.isProfileTabLoading = true;
            });
        builder.addCase(
            updateDocuments.fulfilled, (state, { payload }) => {
                state.isDocumentUpdate = true;
                state.isProfileTabLoading = false;
            });
        builder.addCase(
            updateDocuments.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            updateFileType.pending, (state) => {
                state.isProfileTabLoading = true;
            });
        builder.addCase(
            updateFileType.fulfilled, (state, { payload }) => {
                state.isFileTypeUpdate = true;
                state.isProfileTabLoading = false;
            });
        builder.addCase(
            updateFileType.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            deleteCv.pending, (state) => {
                state.isProfileTabLoading = true;
            });
        builder.addCase(
            deleteCv.fulfilled, (state, { payload }) => {
                state.isDocumentDelete = true;
                state.isProfileTabLoading = false;
            });
        builder.addCase(
            deleteCv.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            listAllTags.pending, (state) => {
                state.isPreTagsLoading = true;
            });
        builder.addCase(
            listAllTags.fulfilled, (state, { payload }) => {
                state.preTagsList = payload.result;
                state.isPreTagsLoading = false;
            });
        builder.addCase(
            listAllTags.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            candidateAllTechSkillsTag.pending, (state) => {
                //state.isTagsLoading = true;
            });
        builder.addCase(
            candidateAllTechSkillsTag.fulfilled, (state, { payload }) => {
                state.tagsList = payload.result;
                state.isTagsLoading = false;
            });
        builder.addCase(
            candidateAllTechSkillsTag.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            candidateAllJobProfileSkillsTag.pending, (state) => {
                //state.isTagsLoading = true;
            });
        builder.addCase(
            candidateAllJobProfileSkillsTag.fulfilled, (state, { payload }) => {
                state.jobProfiletagsList = payload.result;
                state.isTagsLoading = false;
            });
        builder.addCase(
            candidateAllJobProfileSkillsTag.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            listSelectedTags.pending, (state) => {
                state.isTagsLoading = true;
            });
        builder.addCase(
            listSelectedTags.fulfilled, (state, { payload }) => {
                state.tagsList = payload.result;
                state.isTagsLoading = false;
            });
        builder.addCase(
            listSelectedTags.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            profileListSelectedTags.pending, (state) => {
                state.isTagsLoading = true;
            });
        builder.addCase(
            profileListSelectedTags.fulfilled, (state, { payload }) => {
                state.jobProfiletagsList = payload.result;
                state.isTagsLoading = false;
            });
        builder.addCase(
            profileListSelectedTags.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            addTechTags.pending, (state) => {
                state.isTagsLoading = true;
            });
        builder.addCase(
            addTechTags.fulfilled, (state, { payload }) => {
                state.tagsList.push(payload.result);
            });
        builder.addCase(
            addTechTags.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            addJobProfileTags.pending, (state) => {
                state.isTagsLoading = true;
            });
        builder.addCase(
            addJobProfileTags.fulfilled, (state, { payload }) => {
                state.jobProfiletagsList.push(payload.result);
            });
        builder.addCase(
            addJobProfileTags.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            removeTechTags.pending, (state) => {
                state.isTagsLoading = true;
            });
        builder.addCase(
            removeTechTags.fulfilled, (state, action) => {
                state.tagsList = state.tagsList.filter((item) => item.id !== action.meta.arg.id);
            });
        builder.addCase(
            removeTechTags.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            removeJobProfileTags.pending, (state) => {
                state.isTagsLoading = true;
            });
        builder.addCase(
            removeJobProfileTags.fulfilled, (state, action) => {
                state.jobProfiletagsList = state.jobProfiletagsList.filter((item) => item.id !== action.meta.arg.id);
            });
        builder.addCase(
            removeJobProfileTags.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });
    }
});
function isEmpty(obj) {
    for (var i in obj) { return false; }
    return true;
}
export const { clearState, updateProfileDetails, updateRa, updateRc, updateAm, updateOtherdata } = CandidateSlice.actions;
export default CandidateSlice.reducer;